import axios from "axios";

let base = "/deliveries/";

const get = async ({ orderId, date }) =>
  (
    await axios.get(base + "delivery?role=kitchen", {
      params: { orderId, date },
    })
  ).data;

const getNearest = async ({ date, mealTime, latitude, longitude }) =>
  (
    await axios.get(base + "nearest", {
      params: {
        date,
        mealTime,
        latitude,
        longitude,
      },
    })
  ).data;

const complete = async ({ deliveryId }) =>
  (await axios.post(base + deliveryId + "/complete")).data;

const miss = async ({ deliveryId, reason }) =>
  (await axios.post(base + deliveryId + "/miss", { reason })).data;

const deliveries = {
  get,
  getNearest,
  complete,
  miss,
};

export default deliveries;
