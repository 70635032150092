import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import FormComponents from "../../../components/FormComponents";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import {
  initialValues,
  validationSchema,
  formBuilder,
} from "../utils/menuScheduleFormData";

const MenuScheduleForm = () => {
  const navigate = useNavigate();
  const { cuisine, mealType, mealTime, day } = useParams();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await api.menus.create({
          cuisine,
          mealType,
          mealTime,
          ...values,
        });
        await api.menuSchedules.schedule({
          menuId: res.data.menu._id,
          day,
        });
        toast.success("Menu created and scheduled successfully");
        navigate(-1);
      } catch (err) {
        handleError(err);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormComponents data={formBuilder} formik={formik} />
      <Button fullWidth variant="contained" type="submit">
        Schedule Menu
      </Button>
    </form>
  );
};

export default MenuScheduleForm;
