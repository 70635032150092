import axios from "axios";

let base = "/dispatchTasks/";

const getAllDispatchTasks = async ({ date }) =>
  (await axios.get(base, { params: { date } })).data;

const getAllDeliveries = async ({ date, cuisine, mealType, mealTime }) =>
  (
    await axios.get(base + "deliveries", {
      params: { date, cuisine, mealType, mealTime },
    })
  ).data;

const dispatchAllDeliveries = async ({ date, cuisine, mealType, mealTime }) =>
  (
    await axios.post(base + "deliveries/dispatchAll", {
      date,
      cuisine,
      mealType,
      mealTime,
    })
  ).data;

const missAllDeliveries = async ({
  date,
  cuisine,
  mealType,
  mealTime,
  reason,
}) =>
  (
    await axios.post(base + "deliveries/missAll", {
      date,
      cuisine,
      mealType,
      mealTime,
      reason,
    })
  ).data;

const dispatchDelivery = async ({ orderId, date }) =>
  (
    await axios.post(base + "deliveries/dispatchOne", {
      orderId,
      date,
    })
  ).data;

const missDelivery = async ({ orderId, date, reason }) =>
  (
    await axios.post(base + "deliveries/missOne", {
      orderId,
      date,
      reason,
    })
  ).data;

const dispatchTasks = {
  getAllDispatchTasks,
  getAllDeliveries,
  dispatchAllDeliveries,
  missAllDeliveries,
  dispatchDelivery,
  missDelivery,
};

export default dispatchTasks;
