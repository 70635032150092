import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Forward } from "@mui/icons-material";
import dateHelpers from "../../../utils/dateHelpers";

const GoToDeliveryTasksPageButton = () => {
  const { date, mealTime } = useParams();
  const navigate = useNavigate();

  if (!dateHelpers.isCurrentDate(date)) return null;

  return (
    <Button
      size="small"
      variant="outlined"
      color="info"
      onClick={() => navigate(`/delivery-tasks?mealTime=${mealTime}`)}
      sx={{ mb: 2 }}
    >
      Go to Delivery tasks page to complete the deliveries <Forward />
    </Button>
  );
};

export default GoToDeliveryTasksPageButton;
