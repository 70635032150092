import { Box, Typography } from "@mui/material";

const PageTitleWithButton = ({ title, button }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        my: 2,
      }}
    >
      <Typography variant="h5">{title}</Typography>
      {button && button}
    </Box>
  );
};

export default PageTitleWithButton;
