import { useCallback, useEffect, useState } from "react";
import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import Filters from "../components/Filters";
import Settlements from "../components/Settlements";

import api from "../../../api";

const SettlementsPage = () => {
  const [settlements, setSettlements] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 5;

  // Filters
  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState("");

  const fetchSettlements = async (page, limit, orderId, status) => {
    try {
      const filters = {};
      if (orderId) {
        filters.order = orderId;
      }
      if (status) {
        filters.status = status;
      }
      const response = await api.settlements.getAllSettlements({
        page: page + 1,
        limit: limit,
        filters: filters,
      });
      setSettlements(response.data.settlements);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      setSettlements([]);
      setTotalCount(0);
    }
  };

  useEffect(() => {
    fetchSettlements(page, rowsPerPage, orderId, status);
  }, [page, rowsPerPage, orderId, status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = useCallback(({ orderId, status }) => {
    setOrderId(orderId);
    setStatus(status);
    setPage(0);
  }, []);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Settlements</PageTitle>
        {settlements.length > 0 && (
          <Filters handleFilterChange={handleFilterChange} />
        )}
        <Settlements
          settlements={settlements}
          page={page}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
          onPageChange={handleChangePage}
        />
      </Container>
    </>
  );
};

export default SettlementsPage;
