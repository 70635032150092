import { TextField } from "@mui/material";

const PincodeInput = ({ formik }) => {
  return (
    <TextField
      fullWidth
      size="small"
      type="text"
      label="Pincode"
      name="pincode"
      value={formik.values?.pincode || ""}
      pattern="^[0-9]*$"
      onChange={(e) => {
        if (e.target.value.length > 6) return;
        const result = e.target.value.replace(/\D/g, "");
        formik.setFieldValue("pincode", result);
      }}
      onBlur={formik.handleBlur}
      error={formik.touched.pincode && formik.errors?.pincode ? true : false}
      helperText={(formik.touched.pincode && formik.errors?.pincode) || " "}
    />
  );
};

export default PincodeInput;
