import axios from "axios";

let base = "/ratings/";

const get = async ({ deliveryId }) =>
  (await axios.get(base + "ratingByDeliveryId/" + deliveryId)).data;

const ratings = {
  get,
};

export default ratings;
