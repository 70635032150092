import { useEffect, useState } from "react";

import TaskCard from "./TaskCard";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import NoListFound from "../../../components/NoListFound";

const TaskList = ({ date }) => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const getAllTasks = async () => {
      try {
        const {
          data: { dispatchTasks },
        } = await api.dispatchTasks.getAllDispatchTasks({
          date,
        });
        setTasks(dispatchTasks);
      } catch (error) {
        handleError(error);
      }
    };
    getAllTasks();
  }, [date]);

  return (
    <>
      {tasks.map(
        ({
          cuisine,
          mealType,
          mealTime,
          numberOfMeals,
          numberOfOrders,
          menuSchedule,
        }) => {
          return (
            <TaskCard
              date={date}
              cuisine={cuisine}
              mealType={mealType}
              mealTime={mealTime}
              numberOfOrders={numberOfOrders}
              numberOfMeals={numberOfMeals}
              menuSchedule={menuSchedule}
            />
          );
        }
      )}
      {tasks.length === 0 && <NoListFound text={"No tasks found"} />}
    </>
  );
};

export default TaskList;
