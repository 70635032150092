import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import CardTitleWithButton from "./CardTitleWithButton";
import LabelValueText from "../../../components/LabelValueText";

const BasicDetailsDisplay = ({
  isEditable,
  kitchenName,
  kitchenTagline,
  ownerName,
  ownerPhoneNumber,
}) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ margin: "10px 0px" }}>
      <CardContent>
        <CardTitleWithButton
          title="Basic Details"
          btnName={isEditable && "Edit"}
          btnClickHandler={() =>
            navigate("/kitchen-registration/basic-details")
          }
        />
        <LabelValueText label="Kitchen Name" value={kitchenName} />
        {kitchenTagline && (
          <LabelValueText label="Kitchen Tagline" value={kitchenTagline} />
        )}
        <LabelValueText label="Owner Name" value={ownerName} />
        <LabelValueText label="Owner Phone Number" value={ownerPhoneNumber} />
      </CardContent>
    </Card>
  );
};

export default BasicDetailsDisplay;
