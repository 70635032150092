import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const DispatchAllButton = ({ fetchDeliveries }) => {
  const { date, cuisine, mealType, mealTime } = useParams();

  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const dispatchAllDeliveries = async () => {
    try {
      await api.dispatchTasks.dispatchAllDeliveries({
        date,
        cuisine,
        mealType,
        mealTime,
      });
      await fetchDeliveries();
      toast.success("Deliveries dispatched successfully");
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Button
        onClick={openDialog}
        size="small"
        variant="contained"
        color="success"
      >
        Dispatch All
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogContent>
          <Typography variant="body1">
            All pending deliveries will be dispatched.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={closeDialog}>
            Back
          </Button>
          <Button color="success" onClick={dispatchAllDeliveries}>
            Dispatch All
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DispatchAllButton;
