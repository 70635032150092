import axios from "axios";

let base = "/kitchens/";

const getMyKitchenDetails = async () =>
  (await axios.get(base + "myKitchenDetails")).data;

const activateMyKitchen = async () =>
  (await axios.post(base + "activateMyKitchen")).data;

const kitchens = {
  getMyKitchenDetails,
  activateMyKitchen,
};

export default kitchens;
