import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import Address from "../../../components/Address";

import api from "../../../api";
import filterObj from "../../../utils/filterObj";
import handleError from "../../../utils/handleError";

const AddressPage = () => {
  const navigate = useNavigate();
  const [initialAddress, setInitialAddress] = useState(null);

  const addressSubmitHandler = async (address) => {
    try {
      await api.kitchenRegistrations.updateAddress(address);
      navigate(`/kitchen-registration/configuration`);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const res = await api.kitchenRegistrations.getMyKitchenRegistration();
        const address = res.data.kitchenRegistration.address;

        if (address) {
          setInitialAddress(
            filterObj(
              address,
              "building",
              "area",
              "landmark",
              "district",
              "state",
              "country",
              "pincode",
              "latitude",
              "longitude"
            )
          );
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchAddress();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Address</PageTitle>
        <Address
          initialAddress={initialAddress}
          submitHandler={addressSubmitHandler}
        />
      </Container>
    </>
  );
};

export default AddressPage;
