import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";

import DeleteIcon from "@mui/icons-material/Delete";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const DeliveryPincodes = ({
  initialPincodeList = [],
  pincodeListHandler,
  readOnly,
}) => {
  const [pincode, setPincode] = useState("");
  const [pincodeList, setPincodeList] = useState([]);

  useEffect(() => {
    if (initialPincodeList.length > 0) {
      setPincodeList(initialPincodeList);
    }
  }, [initialPincodeList]);

  useEffect(() => {
    pincodeListHandler(pincodeList);
  }, [pincodeList, pincodeListHandler]);

  const pincodeAddHandler = async () => {
    try {
      if (pincodeList.includes(pincode)) {
        return toast.error("Pincode already assigned to the list");
      }
      const pincodeDetails = await api.pincode.getDetails({ pincode });
      if (!pincodeDetails) {
        return toast.error("Invalid Pincode");
      }
      setPincodeList((prev) => [...prev, pincode]);
      setPincode("");
    } catch (error) {
      handleError(error);
    }
  };

  const pincodeDeleteHandler = (id) => {
    setPincodeList((prevList) => prevList.filter((_, index) => index !== id));
  };

  return (
    <div>
      {!readOnly && (
        <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0px" }}
        >
          <TextField
            value={pincode}
            pattern="^[0-9]*$"
            onChange={(e) => {
              if (e.target.value.length > 6) return;
              const result = e.target.value.replace(/\D/g, "");
              setPincode(result);
            }}
            variant="outlined"
            size="small"
            label="Enter Delivery Pincode"
          />
          <Button
            style={{ marginLeft: "10px" }}
            variant="contained"
            onClick={pincodeAddHandler}
          >
            Add
          </Button>
        </div>
      )}
      {pincodeList.length > 0 && (
        <div style={{ margin: "10px 0px" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pincode</TableCell>
                  {!readOnly && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {pincodeList.map((pincode, id) => (
                  <TableRow key={id}>
                    <TableCell>{pincode}</TableCell>
                    {!readOnly && (
                      <TableCell>
                        <DeleteIcon onClick={() => pincodeDeleteHandler(id)} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default DeliveryPincodes;
