import { Card, CardContent, Typography } from "@mui/material";

import {
  deliveryStatusToColorMapping,
  deliveryStatusToLabelMapping,
} from "../../../utils/mappings";
import { useDeliveryPageCtx } from "../contexts/DeliveryPageContext";

const DeliveryStatusCard = () => {
  const { deliveryStatus } = useDeliveryPageCtx();

  return (
    <Card
      sx={{
        margin: "15px 0px",
        background: deliveryStatusToColorMapping[deliveryStatus],
      }}
    >
      <CardContent>
        <Typography variant="h6">Status</Typography>
        <Typography>{deliveryStatusToLabelMapping[deliveryStatus]}</Typography>
      </CardContent>
    </Card>
  );
};

export default DeliveryStatusCard;
