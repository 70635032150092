import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import Select from "../../../components/Select";
import SearchInput from "../../../components/SearchInput";

const Filters = ({ handleFilterChange }) => {
  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState("");

  const orderIdSearchHandler = () => {
    handleFilterChange({ orderId, status });
  };

  const handleStatusChange = (status) => {
    setStatus(status);
    handleFilterChange({ orderId, status });
  };

  useEffect(() => {
    if (orderId === "") {
      handleFilterChange({ orderId, status });
    }
  }, [orderId]);

  return (
    <Box sx={{ margin: "20px 0px", display: "flex" }}>
      <SearchInput
        label={"Order ID"}
        value={orderId}
        setValue={setOrderId}
        sx={{ mr: 2 }}
        searchHandler={orderIdSearchHandler}
      />
      <Select
        label={"Status"}
        options={[
          { value: "pending", label: "Pending" },
          { value: "completed", label: "Completed" },
          { value: "failed", label: "Failed" },
        ]}
        value={status}
        onChange={handleStatusChange}
        clearable={true}
        sx={{ maxWidth: 200 }}
      />
    </Box>
  );
};

export default Filters;
