import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import SubmitKitchenRegistrationButton from "../components/SubmitKitchenRegistrationButton";
import Timeline from "../../../components/Timeline";
import BasicDetailsDisplay from "../components/BasicDetailsDisplay";
import AddressDisplay from "../components/AddressDisplay";
import ConfigurationDisplay from "../components/ConfigurationDisplay";
import PricingDisplay from "../components/PricingDisplay";
import BankDetailsDisplay from "../components/BankDetailsDisplay";
import LegalDetailsDisplay from "../components/LegalDetailsDisplay";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const SummaryPage = () => {
  const navigate = useNavigate();
  const [kitchenRegistration, setKitchenRegistration] = useState({});

  const isEditable = ["created", "declined"].includes(
    kitchenRegistration.status
  );

  const getKitchenRegistration = useCallback(async () => {
    try {
      const res = await api.kitchenRegistrations.getMyKitchenRegistration();
      setKitchenRegistration(res.data.kitchenRegistration);
    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    getKitchenRegistration();
  }, [getKitchenRegistration]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>{kitchenRegistration.name}</PageTitle>
        {isEditable && (
          <SubmitKitchenRegistrationButton
            fetchKitchenRegistration={getKitchenRegistration}
          />
        )}
        {kitchenRegistration.timeline && (
          <Timeline timeline={kitchenRegistration.timeline} />
        )}
        {kitchenRegistration.approvedKitchen && (
          <Button
            onClick={() => navigate("/")}
            fullWidth
            variant="contained"
            sx={{ margin: "10px 0px" }}
            color="success"
            endIcon={<ArrowForward />}
          >
            Go to Kitchen Page
          </Button>
        )}
        {kitchenRegistration.ownerDetails && (
          <BasicDetailsDisplay
            isEditable={isEditable}
            kitchenName={kitchenRegistration.name}
            kitchenTagline={kitchenRegistration.tagline}
            ownerName={kitchenRegistration.ownerDetails.name}
            ownerPhoneNumber={kitchenRegistration.ownerDetails.mobile}
          />
        )}
        {kitchenRegistration.address && (
          <AddressDisplay
            isEditable={isEditable}
            address={kitchenRegistration.address}
          />
        )}
        {kitchenRegistration.configuration && (
          <ConfigurationDisplay
            isEditable={isEditable}
            configuration={kitchenRegistration.configuration}
          />
        )}
        {kitchenRegistration.configuration && kitchenRegistration.pricing && (
          <PricingDisplay
            isEditable={isEditable}
            configuration={kitchenRegistration.configuration}
            pricing={kitchenRegistration.pricing}
          />
        )}
        {kitchenRegistration.bankDetails && (
          <BankDetailsDisplay
            isEditable={isEditable}
            bankDetails={kitchenRegistration.bankDetails}
          />
        )}
        {kitchenRegistration.legalDetails && (
          <LegalDetailsDisplay
            isEditable={isEditable}
            legalDetails={kitchenRegistration.legalDetails}
          />
        )}
      </Container>
    </>
  );
};

export default SummaryPage;
