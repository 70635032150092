import { useOrderPageContext } from "../contexts/OrderPageContext";
import SectionTitleWithButton from "../../../components/SectionTitleWithButton";
import DeliverySummary from "./DeliverySummary";

const DeliveryManagement = () => {
  const { order } = useOrderPageContext();

  return (
    <>
      {order.finalStatus !== "pending" && !order.isPaymentExpired && (
        <>
          <SectionTitleWithButton title="Delivery" />
          <DeliverySummary order={order} />
        </>
      )}
    </>
  );
};

export default DeliveryManagement;
