import getCurrentTimeInSeconds from "../../../utils/getCurrentTimeInSeconds";
import railwayTimeToSeconds from "../../../utils/railwayTimeToSeconds";

export const isTimeBeforeRange = (range) => {
  const currentTime = getCurrentTimeInSeconds();

  const startTime = railwayTimeToSeconds(range.start);

  return currentTime < startTime;
};

export const isTimeInRange = (range) => {
  const currentTime = getCurrentTimeInSeconds();

  const startTime = railwayTimeToSeconds(range.start);
  const endTime = railwayTimeToSeconds(range.end);

  return currentTime >= startTime && currentTime <= endTime;
};

export const isTimeAfterRange = (range) => {
  const currentTime = getCurrentTimeInSeconds();

  const endTime = railwayTimeToSeconds(range.end);

  return currentTime > endTime;
};
