export const orderStatusToMuiColor = {
  pending: "warning",
  active: "success",
  cancelled: "error",
  expired: "default",
  completed: "success",
};

export const settlementStatusToColor = {
  pending: "orange",
  completed: "green",
  failed: "red",
};

export const expiryReasonMapping = {
  payment: "Payment timed out",
  miss: "Miss limit breach by kitchen",
  end: "Order End date crossed",
};

export const deliveryStatusToLabelMapping = {
  completed: "Delivery completed",
  skipped: "Skipped by user",
  missed: "Missed by kitchen",
  deliverable: "Upcoming delivery",
  nonDeliverable: "Non-deliverable date",
  dispatched: "Dispatched",
  invalid: "Invalid date",
};

export const deliveryStatusToColorMapping = {
  completed: "#2E7D32",
  skipped: "#ED6C02",
  missed: "#D32F2F",
  deliverable: "lightgreen",
  nonDeliverable: "gray",
  dispatched: "orange",
  invalid: "#D32F2F",
};

export const deliverystatusToMuiColor = {
  pending: "warning",
  dispatched: "info",
  completed: "success",
  missed: "error",
};
