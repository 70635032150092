import { useEffect, useState } from "react";
import MenuDisplay from "../../../components/MenuDisplay";
import api from "../../../api";
import { useKitchen } from "../../../contexts/KitchenContext";
import { useParams } from "react-router-dom";
import getDayFromDate from "../../../utils/getDayFromDate";
import handleError from "../../../utils/handleError";
import { Box } from "@mui/material";

const MenuSchedule = () => {
  const { date, cuisine, mealType, mealTime } = useParams();
  const { kitchen } = useKitchen();

  const [menuSchedule, setMenuSchedule] = useState({ menu: {} });

  useEffect(() => {
    const fetchMenuSchedule = async () => {
      try {
        const {
          data: { menuSchedule },
        } = await api.menuSchedules.get({
          kitchenId: kitchen._id,
          cuisine,
          mealType,
          mealTime,
          day: getDayFromDate(date),
        });
        setMenuSchedule(menuSchedule);
      } catch (error) {
        handleError(error);
      }
    };
    fetchMenuSchedule();
  }, [kitchen, cuisine, mealType, mealTime, date]);

  return (
    <Box sx={{ my: 2 }}>
      <MenuDisplay
        title={menuSchedule.menu.title}
        description={menuSchedule.menu.description}
      />
    </Box>
  );
};

export default MenuSchedule;
