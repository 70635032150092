import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import { useData } from "../../../contexts/DataContext";
import {
  isTimeAfterRange,
  isTimeBeforeRange,
  isTimeInRange,
} from "../utils/deliveryWindowTimeHelpers";
import convertToAMPM from "../../../utils/convertToAMPM";
import dateHelpers from "../../../utils/dateHelpers";

const DeliveryWindowAlert = () => {
  const { date, mealTime } = useParams();
  const { mealTimeDeliveryWindow } = useData();
  const range = mealTimeDeliveryWindow[mealTime];

  if (!dateHelpers.isCurrentDate(date)) return null;

  if (isTimeBeforeRange(range)) {
    return (
      <Alert severity="info" sx={{ mb: 2 }}>
        Delivery window will be open from {convertToAMPM(range.start)} to{" "}
        {convertToAMPM(range.end)}
      </Alert>
    );
  }

  if (isTimeInRange(range)) {
    return (
      <Alert severity="success" sx={{ mb: 2 }}>
        Delivery window is active and will be closed at{" "}
        {convertToAMPM(range.end)}
      </Alert>
    );
  }

  if (isTimeAfterRange(range)) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        Delivery window is closed at {convertToAMPM(range.end)}
      </Alert>
    );
  }

  return null;
};

export default DeliveryWindowAlert;
