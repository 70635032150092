import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import CardTitleWithButton from "./CardTitleWithButton";
import LabelValueText from "../../../components/LabelValueText";

const BankDetailsDisplay = ({ isEditable, bankDetails }) => {
  const navigate = useNavigate();

  let { accountNumber, ifscCode } = bankDetails;

  return (
    <Card sx={{ margin: "10px 0px" }}>
      <CardContent>
        <CardTitleWithButton
          title="Bank Details"
          btnName={isEditable && "Edit"}
          btnClickHandler={() => navigate("/kitchen-registration/bank-details")}
        />
        <LabelValueText label="Account Number" value={accountNumber} />
        <LabelValueText label="IFSC Code" value={ifscCode} />
      </CardContent>
    </Card>
  );
};

export default BankDetailsDisplay;
