import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, CardContent, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import MenuDisplay from "../../../components/MenuDisplay";

import { useData } from "../../../contexts/DataContext";

const MenuScheduleDayCard = ({ day, menuSchedule }) => {
  const navigate = useNavigate();
  const { cuisine, mealType, mealTime } = useParams();
  const data = useData();

  const clickHandler = async () => {
    navigate(`/menu-schedules/${cuisine}/${mealType}/${mealTime}/${day}`);
  };

  return (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <Typography sx={{ mb: 1 }}>{data.dayLabels[day]}</Typography>
        {menuSchedule ? (
          <MenuDisplay
            title={menuSchedule.menu.title}
            description={menuSchedule.menu.description}
            hideMenuLabel
          />
        ) : (
          <Alert severity="warning">No Menu Scheduled</Alert>
        )}
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={clickHandler}
          endIcon={<ArrowForward />}
          color={menuSchedule ? "primary" : "warning"}
          sx={{ mt: 1 }}
        >
          {menuSchedule ? "Change Menu" : "Schedule Menu"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default MenuScheduleDayCard;
