import { useOrderPageContext } from "../contexts/OrderPageContext";
import SectionTitleWithButton from "../../../components/SectionTitleWithButton";
import PaymentSummary from "./PaymentSummary";

const Payment = () => {
  const { order } = useOrderPageContext();

  return (
    <>
      <SectionTitleWithButton title={"Payment"} />
      <PaymentSummary payment={order.payment} />
    </>
  );
};

export default Payment;
