import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import CategoryTitle from "../../../components/CategoryTitle";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import { useData } from "../../../contexts/DataContext";

const PendingMenuSchedulesPage = () => {
  const data = useData();
  const navigate = useNavigate();
  const { cuisine, mealType, mealTime } = useParams();
  const [pendingMenuScheduleDays, setPendingMenuScheduleDays] = useState([]);

  useEffect(() => {
    const fetchPendingMenuScheduleDays = async () => {
      try {
        const {
          data: { pendingMenuScheduleDays },
        } = await api.menuSchedules.getPendingMenuScheduleDays({
          cuisine,
          mealType,
          mealTime,
        });
        setPendingMenuScheduleDays(pendingMenuScheduleDays);
      } catch (error) {
        handleError(error);
      }
    };
    fetchPendingMenuScheduleDays();
  }, [cuisine, mealType, mealTime, navigate]);

  if (pendingMenuScheduleDays.length === 0) {
    return navigate("/menu-schedules/pending/categories", { replace: true });
  }

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Pending Menu Schedules</PageTitle>
        <CategoryTitle
          cuisine={cuisine}
          mealType={mealType}
          mealTime={mealTime}
          textAlign="center"
        />
        {pendingMenuScheduleDays.map((day) => (
          <Card sx={{ margin: "10px 0px" }}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{data.dayLabels[day]}</Typography>
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => {
                  navigate(
                    `/menu-schedules/${cuisine}/${mealType}/${mealTime}/${day}`
                  );
                }}
                endIcon={<SendIcon />}
              >
                Schedule
              </Button>
            </CardContent>
          </Card>
        ))}
      </Container>
    </>
  );
};

export default PendingMenuSchedulesPage;
