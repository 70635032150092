import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import api from "../../../api";
import dateHelpers from "../../../utils/dateHelpers";
import getDayFromDate from "../../../utils/getDayFromDate";
import handleError from "../../../utils/handleError";

const DeliveryPageContext = createContext();

export const useDeliveryPageCtx = () => useContext(DeliveryPageContext);

export const DeliveryPageContextProvider = ({ children }) => {
  const { orderId, date } = useParams();

  const [order, setOrder] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [menu, setMenu] = useState(null);
  const [rating, setRating] = useState({});
  const [deliveryStatus, setDeliveryStatus] = useState("");

  const isCurrentDate = dateHelpers.isCurrentDate(date);

  const fetchDetails = useCallback(async () => {
    try {
      // Order
      const {
        data: { order },
      } = await api.orders.get({ orderId });
      setOrder(order);

      // Delivery
      const {
        data: { delivery },
      } = await api.deliveries.get({ orderId, date });
      setDelivery(delivery);

      // Menu
      if (delivery.menu) {
        const { title, description } = delivery.menu;
        setMenu({ title, description });
      } else {
        try {
          const {
            data: { menuSchedule },
          } = await api.menuSchedules.get({
            kitchenId: order.kitchen._id,
            cuisine: order.cuisine,
            mealType: order.mealType,
            mealTime: order.mealTime,
            day: getDayFromDate(date),
          });
          const { title, description } = menuSchedule.menu;
          setMenu({ title, description });
        } catch (error) {
          console.error(error);
          setMenu(null);
        }
      }

      // Rating
      if (delivery._id) {
        const rating = await api.ratings.get({ deliveryId: delivery._id });
        setRating(rating.data.rating);
      }
    } catch (error) {
      handleError(error);
    }
  }, [orderId, date]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  useEffect(() => {
    const findDeliveryStatus = () => {
      const {
        startDate,
        deliverableDates = [],
        nonDeliverableDates = [],
        completedDates = [],
        skippedDates = [],
        missedDates = [],
        cutoffDate,
      } = order;
      let calculatedStatus = "";
      if (
        new Date(startDate) <= new Date(date) &&
        new Date(date) <= new Date(cutoffDate)
      ) {
        if (completedDates.includes(date)) {
          calculatedStatus = "completed";
        } else if (skippedDates.includes(date)) {
          calculatedStatus = "skipped";
        } else if (missedDates.includes(date)) {
          calculatedStatus = "missed";
        } else if (deliverableDates.includes(date)) {
          calculatedStatus = "deliverable";
        } else if (nonDeliverableDates.includes(date)) {
          calculatedStatus = "nonDeliverable";
        } else {
          calculatedStatus = "invalid";
        }
      } else {
        calculatedStatus = "invalid";
      }
      if (
        calculatedStatus !== "invalid" &&
        isCurrentDate &&
        delivery.status === "dispatched"
      ) {
        calculatedStatus = "dispatched";
      }
      setDeliveryStatus(calculatedStatus);
    };

    if (order && date && delivery) {
      findDeliveryStatus();
    }
  }, [order, date, isCurrentDate, delivery]);

  const contextValue = {
    order,
    delivery,
    menu,
    rating,
    deliveryStatus,
    fetchDetails,
  };

  const isDataReady = Boolean(order && delivery && deliveryStatus);

  return (
    <DeliveryPageContext.Provider value={contextValue}>
      {isDataReady ? children : null}
    </DeliveryPageContext.Provider>
  );
};
