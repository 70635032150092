import { Typography } from "@mui/material";

import CardTitle from "./CardTitle";
import GreyCard from "./GreyCard";

const MenuDisplay = ({ title, description, hideMenuLabel }) => {
  return (
    <GreyCard>
      {!hideMenuLabel && <CardTitle title="Menu" />}
      <Typography>
        <strong>{title}</strong>: {description}
      </Typography>
    </GreyCard>
  );
};

export default MenuDisplay;
