import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import FormComponents from "../../../components/FormComponents";

const schema = yup.object({
  reason: yup.string().required("Reason is required"),
});

const MissAllButton = ({ fetchDeliveries }) => {
  const { date, cuisine, mealType, mealTime } = useParams();

  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    formik.resetForm();
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        await api.dispatchTasks.missAllDeliveries({
          date,
          cuisine,
          mealType,
          mealTime,
          reason: values.reason,
        });
        await fetchDeliveries();
        toast.success("Deliveries missed successfully");
      } catch (error) {
        handleError(error);
      } finally {
        formik.resetForm();
        closeDialog();
      }
    },
  });

  return (
    <>
      <Button
        onClick={openDialog}
        size="small"
        variant="contained"
        color="error"
      >
        Miss All
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Typography sx={{ mb: 1 }}>
              Are you sure you want to miss all pending deliveries?
            </Typography>
            <FormComponents
              formik={formik}
              data={[
                {
                  type: "input",
                  label: "Reason",
                  state: "reason",
                  multiline: true,
                  rows: 3,
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" color="success" onClick={closeDialog}>
              Back
            </Button>
            <Button type="submit" color="error">
              Miss All
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default MissAllButton;
