function getCurrentTimeInSeconds() {
  const now = new Date();

  // Get the hours, minutes, and seconds
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  // Calculate total seconds since midnight
  return hours * 3600 + minutes * 60 + seconds;
}

export default getCurrentTimeInSeconds;
