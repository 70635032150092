import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

import Header from "../../../components/Header";
import PageTitleWithButton from "../../../components/PageTitleWithButton";

import api from "../../../api";
import { useKitchen } from "../../../contexts/KitchenContext";
import handleError from "../../../utils/handleError";

const KitchenUsersPage = () => {
  const navigate = useNavigate();
  const { kitchenRole } = useKitchen();
  const [kitchenUsers, setKitchenUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [kitchenUserToDelete, setKitchenUserToDelete] = useState(null);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const fetchKitchenUsers = async () => {
    try {
      const {
        data: { kitchenUsers },
      } = await api.kitchenUsers.getAll();
      setKitchenUsers(kitchenUsers);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchKitchenUsers();
  }, []);

  const handleDelete = async () => {
    try {
      await api.kitchenUsers.delete({ kitchenUserId: kitchenUserToDelete._id });
      toast.success("Kitchen user deleted successfully");
      await fetchKitchenUsers();
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Header />
      <Container>
        <PageTitleWithButton
          title={"Kitchen Users"}
          button={
            <Button
              size="small"
              variant="contained"
              endIcon={<ArrowForward />}
              onClick={() => navigate("/kitchen-users/invites")}
            >
              Invites
            </Button>
          }
        />
        {kitchenUsers.map((kitchenUser) => (
          <Card sx={{ margin: "10px 0px" }}>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Chip size="small" label={kitchenUser.role} />
                <Typography fontSize={12} marginTop={1}>
                  {kitchenUser.user.email}
                </Typography>
              </Box>
              {(["chef", "rider"].includes(kitchenUser.role) ||
                (kitchenRole === "owner" && kitchenUser.role === "admin")) && (
                <IconButton
                  color="error"
                  onClick={() => {
                    setKitchenUserToDelete(kitchenUser);
                    openDialog();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </CardContent>
          </Card>
        ))}
      </Container>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Delete Kitchen user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this kitchen user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KitchenUsersPage;
