import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Container } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import UserAccountIcon from "./UserAccountIcon";
import Logo from "./Logo";

import { useAuth } from "../../contexts/AuthContext";
import styles from "./Header.module.css";

const Header = ({ hideBackButton }) => {
  const authCtx = useAuth();
  const navigate = useNavigate();

  return (
    <AppBar position="static">
      <Container>
        <Toolbar className={styles.header}>
          <div className={styles.left}>
            {!hideBackButton && (
              <ArrowBackIcon
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
            )}
          </div>
          <div className={styles.center}>
            <Logo />
          </div>
          <div className={styles.right}>
            {authCtx.isLoggedIn && <UserAccountIcon />}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
