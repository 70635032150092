import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  capitalize,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import CategoryTitle from "../../../components/CategoryTitle";

const MenuScheduleCategoryCard = ({
  cuisine,
  mealType,
  mealTime,
  menuScheduleDays,
  clickHandler,
  btnName = "View menu schedules",
  btnColor = "primary",
}) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <CategoryTitle
          cuisine={cuisine}
          mealType={mealType}
          mealTime={mealTime}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap", margin: "10px 0px" }}>
          {menuScheduleDays.map(({ day, menuSchedule }) => (
            <Chip
              key={day}
              label={capitalize(day)}
              size="small"
              sx={{
                backgroundColor:
                  menuSchedule === null ? "lightcoral" : "lightgreen",
                margin: "3px",
                minWidth: "50px",
              }}
            />
          ))}
        </Box>
        <Button
          fullWidth
          variant="contained"
          size="small"
          endIcon={<ArrowForward />}
          onClick={clickHandler}
          color={btnColor}
        >
          {btnName}
        </Button>
      </CardContent>
    </Card>
  );
};

export default MenuScheduleCategoryCard;
