import {
  Button,
  Card,
  CardContent,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import PageTitle from "../../../components/PageTitle";

const ExistingRegistration = ({ kitchenRegistration }) => {
  const navigate = useNavigate();

  const handleContinue = (status) => {
    const path = status === "created" ? "basic-details" : "summary";
    navigate(`/kitchen-registration/${path}`);
  };

  return (
    <>
      <PageTitle>kitchen Registration</PageTitle>
      <Card>
        <CardContent>
          <ListItem style={{ padding: 0 }} key={kitchenRegistration._id}>
            <ListItemText
              primary={kitchenRegistration.name}
              secondary={`Status: ${kitchenRegistration.status}`}
            />
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleContinue(kitchenRegistration.status)}
            >
              Continue
            </Button>
          </ListItem>
        </CardContent>
      </Card>
    </>
  );
};

export default ExistingRegistration;
