import DispatchTasksPage from "./pages/DispatchTasksPage";
import DispatchTaskPage from "./pages/DispatchTaskPage";

const dispatchTaskRoutes = [
  {
    path: "dispatch-tasks",
    component: <DispatchTasksPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "dispatch-tasks/:date/:cuisine/:mealType/:mealTime",
    component: <DispatchTaskPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
];

export default dispatchTaskRoutes;
