import React from "react";
import { Typography, Box, Rating, Card, CardContent } from "@mui/material";
import { useDeliveryPageCtx } from "../contexts/DeliveryPageContext";

import ColonText from "../../../components/ColonText";

const ExistingRating = () => {
  const { rating, deliveryStatus } = useDeliveryPageCtx();

  if (deliveryStatus !== "completed" || !rating._id) return null;

  return (
    <Card
      sx={{
        margin: "15px 0px",
      }}
    >
      <CardContent>
        <Typography variant="h6" marginBottom={"10px"}>
          Your Rating
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="body1">
            <strong>Rating : </strong>
          </Typography>
          <Rating value={rating.rating} />
        </Box>
        <ColonText first={"Review"} second={rating.review} />
      </CardContent>
    </Card>
  );
};

export default ExistingRating;
