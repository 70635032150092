import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormComponents from "../../../components/FormComponents";
import BottomButton from "../../../components/BottomButton";

import api from "../../../api";
import Header from "../../../components/Header";
import handleError from "../../../utils/handleError";
import PageTitle from "../../../components/PageTitle";

const initialValues = {
  pan: "",
  gstin: "",
  fssai: "",
};

const validationSchema = Yup.object({
  pan: Yup.string()
    .required("PAN is required")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format"),
  gstin: Yup.string()
    .required("GSTIN is required")
    .matches(
      /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}\d{1}$/,
      "Invalid GSTIN format"
    ),
  fssai: Yup.string()
    .required("FSSAI is required")
    .matches(/^\d{14}$/, "Invalid FSSAI format"),
});

const LegalDetailsPage = () => {
  const navigate = useNavigate();

  const submitHandler = async (values) => {
    try {
      await api.kitchenRegistrations.updateLegalDetails(values);
      navigate(`/kitchen-registration/summary`);
    } catch (error) {
      handleError(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    const fetchLegalDetails = async () => {
      try {
        const res = await api.kitchenRegistrations.getMyKitchenRegistration();
        const { legalDetails } = res.data.kitchenRegistration;

        if (legalDetails) {
          formik.setValues({
            pan: legalDetails.pan,
            gstin: legalDetails.gstin,
            fssai: legalDetails.fssai,
          });
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchLegalDetails();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Legal details</PageTitle>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents
            formik={formik}
            data={[
              {
                type: "input",
                label: "PAN",
                state: "pan",
                inputType: "text",
              },
              {
                type: "input",
                label: "GSTIN",
                state: "gstin",
                inputType: "text",
              },
              {
                type: "input",
                label: "FSSAI",
                state: "fssai",
                inputType: "text",
              },
            ]}
          />
          <BottomButton>Summary</BottomButton>
        </form>
      </Container>
    </>
  );
};

export default LegalDetailsPage;
