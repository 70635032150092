import { createTheme } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@emotion/react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fce44d",
    },
  },
});

const ThemeProvider = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
