import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import CardTitleWithButton from "./CardTitleWithButton";
import LabelValueText from "../../../components/LabelValueText";

const LegalDetailsDisplay = ({ isEditable, legalDetails }) => {
  const navigate = useNavigate();

  let { fssai, gstin, pan } = legalDetails;

  return (
    <Card sx={{ margin: "10px 0px" }}>
      <CardContent>
        <CardTitleWithButton
          title="Legal Details"
          btnName={isEditable && "Edit"}
          btnClickHandler={() =>
            navigate("/kitchen-registration/legal-details")
          }
        />
        <LabelValueText label="FSSAI" value={fssai} />
        <LabelValueText label="GSTIN" value={gstin} />
        <LabelValueText label="PAN" value={pan} />
      </CardContent>
    </Card>
  );
};

export default LegalDetailsDisplay;
