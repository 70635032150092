import { useState } from "react";
import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import { LocationAccess, Delivery } from "../components";
import NoListFound from "../../../components/NoListFound";

import getMealTimesInDeliveryWindow from "../utils/getMealTimesInDeliveryWindow";
import { useKitchen } from "../../../contexts/KitchenContext";
import { useData } from "../../../contexts/DataContext";
import { getCurrentDay12AM } from "../../../utils/dateHelpers";

const DeliveryTasksPage = () => {
  const { kitchen } = useKitchen();
  const { mealTimeDeliveryWindow } = useData();
  const date = getCurrentDay12AM();
  const [currentLocation, setCurrentLocation] = useState({});

  const isCurrentLocationAvailable = Boolean(
    currentLocation.latitude && currentLocation.longitude
  );
  const mealTimesInDeliveryWindow = getMealTimesInDeliveryWindow({
    mealTimes: kitchen.configuration.mealTimes,
    deliveryWindow: mealTimeDeliveryWindow,
  });

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Delivery Tasks</PageTitle>
        {!isCurrentLocationAvailable && (
          <LocationAccess onCurrentLocationChange={setCurrentLocation} />
        )}
        {isCurrentLocationAvailable &&
          mealTimesInDeliveryWindow.map((mealTime) => (
            <Delivery
              date={date}
              mealTime={mealTime}
              currentLocation={currentLocation}
            />
          ))}
        {mealTimesInDeliveryWindow.length === 0 && (
          <NoListFound text={"No delivery tasks found"} />
        )}
      </Container>
    </>
  );
};

export default DeliveryTasksPage;
