import { useOrderPageContext } from "../contexts/OrderPageContext";
import SectionTitleWithButton from "../../../components/SectionTitleWithButton";
import SettlementSummary from "./SettlementSummary";

const Settlement = () => {
  const { order } = useOrderPageContext();

  if (!order.settlement) return null;

  return (
    <>
      <SectionTitleWithButton title={"Settlement"} />
      <SettlementSummary settlement={order.settlement} />
    </>
  );
};

export default Settlement;
