import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent, Typography } from "@mui/material";
import { toast } from "react-toastify";

import ColonText from "../../../components/ColonText";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const SelectMenu = ({ menus }) => {
  const navigate = useNavigate();
  const { day } = useParams();

  const menuClickHandler = async (menuId) => {
    try {
      await api.menuSchedules.schedule({
        menuId,
        day,
      });
      toast.success("Menu scheduled successfully");
      navigate(-1);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Typography textAlign={"center"} sx={{ mb: 2 }}>
        Choose from saved menus
      </Typography>
      {menus.map((menu) => {
        return (
          <Card
            key={menu._id}
            onClick={() => menuClickHandler(menu._id)}
            sx={{ cursor: "pointer", mb: 2 }}
          >
            <CardContent>
              <ColonText first={menu.title} second={menu.description} />
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export default SelectMenu;
