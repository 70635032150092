import { Typography } from "@mui/material";

const PageTitle = ({ children }) => {
  return (
    <Typography variant="h5" textAlign={"center"} sx={{ my: 2 }}>
      {children}
    </Typography>
  );
};

export default PageTitle;
