import { Box } from "@mui/material";
import DispatchAllButton from "./DispatchAllButton";
import MissAllButton from "./MissAllButton";
import dateHelpers from "../../../utils/dateHelpers";
import { useParams } from "react-router-dom";

const ActionButtons = ({ fetchDeliveries }) => {
  const { date } = useParams();

  if (!dateHelpers.isCurrentDate(date)) return null;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        mb: 2,
      }}
    >
      <DispatchAllButton fetchDeliveries={fetchDeliveries} />
      <MissAllButton fetchDeliveries={fetchDeliveries} />
    </Box>
  );
};

export default ActionButtons;
