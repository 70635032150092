import { Card, CardContent } from "@mui/material";

import ColonText from "../../../components/ColonText";
import DeliveryAddress from "./DeliveryAddress";
import DeliveryActions from "./DeliveryActions";
import CategoryTitle from "../../../components/CategoryTitle";
import MenuDisplay from "../../../components/MenuDisplay";

const DeliveryCard = ({ delivery, fetchNearestDelivery }) => {
  return (
    <Card key={delivery._id} style={{ marginBottom: "16px" }}>
      <CardContent>
        <ColonText first={"Delivery ID"} second={delivery._id} />
        <ColonText
          first={"Meal Plan"}
          second={
            <CategoryTitle
              cuisine={delivery.cuisine}
              mealType={delivery.mealType}
              mealTime={delivery.mealTime}
            />
          }
        />
        <MenuDisplay
          title={delivery.menu.title}
          description={delivery.menu.description}
        />
        <DeliveryAddress address={delivery.address} />
        <DeliveryActions
          deliveryId={delivery._id}
          fetchNearestDelivery={fetchNearestDelivery}
        />
      </CardContent>
    </Card>
  );
};

export default DeliveryCard;
