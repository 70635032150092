import { Routes, Route, Navigate } from "react-router-dom";

import AuthGuard from "./routeGuards/AuthGuard";
import KitchenGuard from "./routeGuards/KitchenGuard";
import DataGuard from "./routeGuards/DataGuard";

import routes from "./routes";

const Router = () => {
  const renderedRoutes = routes.map((route) => {
    const { path, component, allowedKitchenRoles, isProtected } = route;
    const componentWithDataGuard = <DataGuard>{component}</DataGuard>;

    return isProtected ? (
      <Route
        key={path}
        path={path}
        element={
          <AuthGuard>
            <KitchenGuard allowedKitchenRoles={allowedKitchenRoles}>
              {componentWithDataGuard}
            </KitchenGuard>
          </AuthGuard>
        }
      />
    ) : (
      <Route key={path} path={path} element={componentWithDataGuard} />
    );
  });

  const wildCardRoute = <Route path="*" element={<Navigate to="/" />} />;

  return (
    <Routes>
      {renderedRoutes}
      {wildCardRoute}
    </Routes>
  );
};

export default Router;
