import CreateMenu from "./CreateMenu";
import OrDivider from "../../../components/OrDivider";
import SelectMenu from "./SelectMenu";

const CreateOrSelectMenu = ({ menus }) => {
  return (
    <>
      <CreateMenu />
      <OrDivider />
      <SelectMenu menus={menus} />
    </>
  );
};

export default CreateOrSelectMenu;
