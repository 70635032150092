import { Typography, Card, CardContent, Box } from "@mui/material";

const findLength = (deliveries, status) =>
  deliveries.filter((delivery) => delivery.status === status).length;

const SummaryItem = ({ label, value }) => (
  <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", mb: 1 }}>
    <Typography>{label}:</Typography>
    <Typography>{value}</Typography>
  </Box>
);

const DeliverySummary = ({ deliveries }) => {
  const totalDeliveries = deliveries.length;
  const pendingDeliveries = findLength(deliveries, "pending");
  const dispatchedDeliveries = findLength(deliveries, "dispatched");
  const missedDeliveries = findLength(deliveries, "missed");
  const completedDeliveries = findLength(deliveries, "completed");

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <SummaryItem label="Total" value={totalDeliveries} />
        <SummaryItem label="Pending" value={pendingDeliveries} />
        <SummaryItem label="Dispatched" value={dispatchedDeliveries} />
        <SummaryItem label="Missed" value={missedDeliveries} />
        <SummaryItem label="Completed" value={completedDeliveries} />
      </CardContent>
    </Card>
  );
};

export default DeliverySummary;
