import { useNavigate } from "react-router-dom";
import { Button, Container } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import MenuFormComponents from "../components/MenuFormComponents";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import { initialValues, validationSchema } from "../utils/menuFormData";

const AddMenuPage = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.menus.create(values);
        toast.success("Menu added successfully");
        navigate(-1);
      } catch (err) {
        handleError(err);
      }
    },
  });

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Add Menu</PageTitle>
        <form onSubmit={formik.handleSubmit}>
          <MenuFormComponents formik={formik} />
          <Button type="submit" fullWidth variant="contained">
            Add Menu
          </Button>
        </form>
      </Container>
    </>
  );
};

export default AddMenuPage;
