import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Chip,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import Header from "../../../components/Header";
import PageTitleWithButton from "../../../components/PageTitleWithButton";

import api from "../../../api";

import handleError from "../../../utils/handleError";
import kitchenUserInviteToMuiColor from "../utils/kitchenUserInviteToMuiColor";
import NoListFound from "../../../components/NoListFound";

const KitchenUserInvitesPage = () => {
  const navigate = useNavigate();
  const [kitchenUserInvites, setKitchenUserInvites] = useState([]);

  const fetchInvites = async () => {
    try {
      const res = await api.kitchenUserInvites.getAllSentInvites();
      const { kitchenUserInvites } = res.data;
      setKitchenUserInvites(kitchenUserInvites);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  const handleCancelInvite = async (inviteId) => {
    try {
      await api.kitchenUserInvites.cancelInvite({
        inviteId,
      });
      fetchInvites();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <PageTitleWithButton
          title={"Invites"}
          button={
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate("/kitchen-users/invites/send")}
              endIcon={<ArrowForward />}
            >
              Send invite
            </Button>
          }
        />
        {kitchenUserInvites.length === 0 && (
          <NoListFound text={"No invites found"} />
        )}
        {kitchenUserInvites.map((invite) => (
          <Card key={invite._id} sx={{ margin: "5px 0px" }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Chip label={invite.role} size="small" />
                <Chip
                  size="small"
                  label={invite.finalStatus}
                  color={kitchenUserInviteToMuiColor[invite.finalStatus]}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 8,
                }}
              >
                <Typography sx={{ fontSize: 13 }} marginTop={1}>
                  {invite.email}
                </Typography>
                {invite.finalStatus === "pending" && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleCancelInvite(invite._id)}
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
        ))}
      </Container>
    </>
  );
};

export default KitchenUserInvitesPage;
