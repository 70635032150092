import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Card, CardContent, Button } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

import CardTitleWithButton from "./CardTitleWithButton";
import LabelValueText from "../../../components/LabelValueText";
import PricePreviewDrawer from "./PricePreviewDrawer";

import isEmptyObject from "../../../utils/isEmptyObject";
import { useData } from "../../../contexts/DataContext";

const MultiplierList = ({ title, multipliers, labelKey }) => {
  const data = useData();

  const { cuisineLabels, mealTypeLabels, mealTimeLabels, periodLabels } = data;

  let labelsSource = {};

  if (labelKey === "cuisine") labelsSource = cuisineLabels;
  if (labelKey === "mealType") labelsSource = mealTypeLabels;
  if (labelKey === "mealTime") labelsSource = mealTimeLabels;
  if (labelKey === "period") labelsSource = periodLabels;

  return (
    <div style={{ margin: "5px 0px" }}>
      <Typography variant="body1">
        <strong>{title}</strong>
      </Typography>
      {multipliers.map((item) => (
        <Typography variant="body2">
          {labelsSource[item[labelKey]]} : {item.multiplier}
        </Typography>
      ))}
    </div>
  );
};

const PricingDisplay = ({ isEditable, configuration, pricing }) => {
  const navigate = useNavigate();
  const [isPricePreviewOpen, setIsPricePreviewOpen] = useState(false);

  if (isEmptyObject(pricing) || isEmptyObject(configuration)) return null;

  return (
    <>
      <Card style={{ margin: "10px 0px" }}>
        <CardContent>
          <CardTitleWithButton
            title="Pricing"
            btnName={isEditable && "Edit"}
            btnClickHandler={() => navigate("/kitchen-registration/pricing")}
          />
          <LabelValueText label="Base Price" value={"₹ " + pricing.basePrice} />
          <MultiplierList
            title="Cuisine Multipliers"
            multipliers={pricing.cuisineMultipliers}
            labelKey="cuisine"
          />
          <MultiplierList
            title="Meal Type Multipliers"
            multipliers={pricing.mealTypeMultipliers}
            labelKey="mealType"
          />
          <MultiplierList
            title="Meal Time Multipliers"
            multipliers={pricing.mealTimeMultipliers}
            labelKey="mealTime"
          />
          <MultiplierList
            title="Period Multipliers"
            multipliers={pricing.periodMultipliers}
            labelKey="period"
          />
          <Button
            fullWidth
            variant="contained"
            size="small"
            endIcon={<ArrowUpward />}
            onClick={() => setIsPricePreviewOpen(true)}
            sx={{ marginTop: "10px" }}
          >
            Price preview
          </Button>
        </CardContent>
      </Card>
      <PricePreviewDrawer
        isOpen={isPricePreviewOpen}
        closeDrawer={() => setIsPricePreviewOpen(false)}
        configuration={configuration}
        pricing={pricing}
        showCloseButton
      />
    </>
  );
};

export default PricingDisplay;
