import { useCallback, useEffect, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";

import RemainingDeliveries from "./RemainingDeliveries";
import DeliveryCard from "./DeliveryCard";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import resetDateTo12AM from "../../../utils/resetDateTo12AM";
import NoListFound from "../../../components/NoListFound";
import { useData } from "../../../contexts/DataContext";

const Delivery = ({ date, mealTime, currentLocation }) => {
  const { mealTimeLabels } = useData();
  const [nearestDelivery, setNearestDelivery] = useState({});
  const [remainingDeliveriesCount, setRemainingDeliveriesCount] = useState(0);

  const fetchNearestDelivery = useCallback(async () => {
    try {
      const {
        data: { delivery, remainingDeliveriesCount },
      } = await api.deliveries.getNearest({
        date: resetDateTo12AM(date),
        mealTime,
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
      });

      setNearestDelivery(delivery);
      setRemainingDeliveriesCount(remainingDeliveriesCount);
    } catch (err) {
      handleError(err);
    }
  }, [
    date,
    mealTime,
    setNearestDelivery,
    currentLocation.latitude,
    currentLocation.longitude,
    setRemainingDeliveriesCount,
  ]);

  useEffect(() => {
    fetchNearestDelivery();
  }, [fetchNearestDelivery]);

  return (
    <Box sx={{ my: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">{mealTimeLabels[mealTime]}</Typography>
        {remainingDeliveriesCount > 0 && (
          <Chip
            color="success"
            label={`Remaining deliveries : ${remainingDeliveriesCount}`}
          />
        )}
      </Box>
      {nearestDelivery._id && (
        <DeliveryCard
          delivery={nearestDelivery}
          fetchNearestDelivery={fetchNearestDelivery}
        />
      )}
      {!nearestDelivery._id && <NoListFound text="No delivery found" />}
    </Box>
  );
};

export default Delivery;
