import { Button } from "@mui/material";
import handleError from "../../../utils/handleError";
import api from "../../../api";
import { useOrderPageContext } from "../contexts/OrderPageContext";

const ClaimsettlementButton = () => {
  const {
    order: { _id: orderId },
    fetchOrder,
  } = useOrderPageContext();

  const handleClaimSettlement = async () => {
    try {
      await api.orders.processExpiredOrder({
        orderId,
      });
      fetchOrder();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="success"
      onClick={handleClaimSettlement}
    >
      Claim Settlement
    </Button>
  );
};

export default ClaimsettlementButton;
