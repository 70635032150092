import { Box, Typography } from "@mui/material";
import NavigationIcon from "./NavigationIcon";
import DialerIcon from "./DialerIcon";
import CardTitle from "../../../components/CardTitle";
import GreyCard from "../../../components/GreyCard";

const DeliveryAddress = ({ address }) => {
  const { latitude, longitude, mobile } = address;
  return (
    <GreyCard>
      <CardTitle title={"Address"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography>{address.fullName}</Typography>
          <Typography>{address.mobile}</Typography>
        </Box>
        <Box>
          <NavigationIcon latitude={latitude} longitude={longitude} />
          <DialerIcon mobile={mobile} />
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary">
        {address.fullName}, {address.area}, {address.district}, {address.state},{" "}
        {address.country}, {address.pincode}
      </Typography>
    </GreyCard>
  );
};

export default DeliveryAddress;
