import { useDeliveryPageCtx } from "../contexts/DeliveryPageContext";
import MenuDisplay from "../../../components/MenuDisplay";

const MenuCard = () => {
  const { deliveryStatus, menu } = useDeliveryPageCtx();

  if (deliveryStatus === "invalid") return null;
  if (!menu) return null;

  return <MenuDisplay title={menu.title} description={menu.description} />;
};

export default MenuCard;
