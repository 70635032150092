import { Container } from "@mui/material";

import Header from "../../../components/Header";
import {
  OrderHeader,
  OrderAlert,
  OrderSummaryCard,
  DeliveryManagement,
  Address,
  Payment,
  Settlement,
} from "../components";
import { OrderPageContextProvider } from "../contexts/OrderPageContext";

const OrderPage = () => {
  return (
    <OrderPageContextProvider>
      <Header />
      <Container>
        <OrderHeader />
        <OrderAlert />
        <OrderSummaryCard />
        <DeliveryManagement />
        <Address />
        <Payment />
        <Settlement />
      </Container>
    </OrderPageContextProvider>
  );
};

export default OrderPage;
