import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import ResetPassword from "../components/ResetPassword";

const ResetPasswordPage = () => {
  return (
    <>
      <Header />
      <Container>
        <PageTitle>Reset Password</PageTitle>
        <ResetPassword />
      </Container>
    </>
  );
};

export default ResetPasswordPage;
