import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import MenuScheduleInfo from "../components/MenuScheduleInfo";
import MenuScheduleForm from "../components/MenuScheduleForm";
import CreateOrSelectMenu from "../components/CreateOrSelectMenu";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const MenuSchedulePage = () => {
  const { cuisine, mealType, mealTime } = useParams();
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const fetchAllMenus = async () => {
      try {
        const {
          data: { menus },
        } = await api.menus.getAll({
          filters: {
            cuisine,
            mealType,
            mealTime,
          },
        });
        setMenus(menus);
      } catch (error) {
        handleError(error);
      }
    };
    fetchAllMenus();
  }, [cuisine, mealType, mealTime]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Menu Schedule</PageTitle>
        <MenuScheduleInfo />
        {menus.length === 0 && <MenuScheduleForm />}
        {menus.length > 0 && <CreateOrSelectMenu menus={menus} />}
      </Container>
    </>
  );
};

export default MenuSchedulePage;
