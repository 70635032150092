import { Alert } from "@mui/material";
import formatTimestamp from "../../../utils/formatTimestamp";
import { expiryReasonMapping } from "../../../utils/mappings";
import { useOrderPageContext } from "../contexts/OrderPageContext";

const OrderAlert = () => {
  const { order } = useOrderPageContext();
  return (
    <>
      {order.finalStatus === "pending" && (
        <Alert
          severity="warning"
          sx={{ margin: "10px 0px" }}
        >{`Please revise the payment before ${formatTimestamp(
          order.payment.expiry
        )} to activate the order`}</Alert>
      )}
      {order.finalStatus === "expired" && (
        <Alert severity="error" sx={{ margin: "10px 0px" }}>
          {`Expiry reason:
              ${expiryReasonMapping[order.expiryDetails.reason]}
            `}
        </Alert>
      )}
    </>
  );
};

export default OrderAlert;
