import { Typography } from "@mui/material";

import { useData } from "../contexts/DataContext";

const CategoryTitle = ({
  cuisine,
  mealType,
  mealTime,
  period,
  textAlign = "left",
}) => {
  const { cuisineLabels, mealTypeLabels, mealTimeLabels, periodLabels } =
    useData();
  return (
    <Typography textAlign={textAlign} gutterBottom>
      {cuisineLabels[cuisine]} {mealTypeLabels[mealType]}{" "}
      {mealTimeLabels[mealTime]}
      {period && `  - ${periodLabels[period]}`}
    </Typography>
  );
};

export default CategoryTitle;
