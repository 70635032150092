import { Typography } from "@mui/material";

const CardTitle = ({ title }) => {
  return (
    <Typography variant="subtitle1" sx={{ mb: 1 }}>
      <strong>{title}</strong>
    </Typography>
  );
};

export default CardTitle;
