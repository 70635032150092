import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Card,
  CardContent,
  Typography,
  capitalize,
} from "@mui/material";
import { toast } from "react-toastify";

import Header from "../../../components/Header";
import OrDivider from "../../../components/OrDivider";

import api from "../../../api";
import { useKitchen } from "../../../contexts/KitchenContext";
import handleError from "../../../utils/handleError";

const KitchenUserInvitesReceivedPage = () => {
  const navigate = useNavigate();
  const [kitchenUserInvites, setKitchenUserInvites] = useState({});
  const { fetchMyKitchenDetails } = useKitchen();

  useEffect(() => {
    const fetchRiderInvite = async () => {
      try {
        const res = await api.kitchenUserInvites.getAllActiveReceivedInvites();
        const { kitchenUserInvites } = res.data;
        setKitchenUserInvites(kitchenUserInvites);
      } catch (error) {
        handleError(error);
      }
    };
    fetchRiderInvite();
  }, []);

  const handleAccept = async (id) => {
    try {
      await api.kitchenUserInvites.acceptInvite({
        inviteId: id,
      });
      toast.success("Invite accepted");
      await fetchMyKitchenDetails();
    } catch (error) {
      handleError(error);
    }
  };

  const handleDecline = async (id) => {
    try {
      await api.kitchenUserInvites.declineInvite({
        inviteId: id,
      });
      toast.success("Invite declined");
      navigate("/", { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Button
          size="small"
          variant="contained"
          fullWidth
          sx={{ marginTop: "20px" }}
          onClick={() => navigate("/kitchen-registration")}
        >
          Register your own kitchen
        </Button>
        <OrDivider />
        <Typography
          textAlign={"center"}
          sx={{ fontSize: "14px" }}
          color={"textSecondary"}
        >
          You can accept or decline requests from other kitchens
        </Typography>
        <Typography textAlign={"center"} sx={{ margin: "10px" }}></Typography>
        {kitchenUserInvites.length > 0 ? (
          kitchenUserInvites.map((kitchenUserInvite) => (
            <Card key={kitchenUserInvite._id} sx={{ margin: "10px 0px" }}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    <strong>{kitchenUserInvite.kitchen.name}</strong> is
                    inviting you to join them as a{" "}
                    <strong>{capitalize(kitchenUserInvite.role)}</strong>
                  </Typography>
                </div>
                {kitchenUserInvite.finalStatus === "pending" && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={() => handleAccept(kitchenUserInvite._id)}
                    >
                      Accept
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => handleDecline(kitchenUserInvite._id)}
                    >
                      Decline
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="h6" align="center">
            No invites received
          </Typography>
        )}
      </Container>
    </>
  );
};

export default KitchenUserInvitesReceivedPage;
