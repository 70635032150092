import * as Yup from "yup";

export const validationSchema = Yup.object({
  title: Yup.string().required("Menu title is required"),
  description: Yup.string().required("Menu description is required"),
});

export const initialValues = {
  title: "",
  description: "",
};

export const formBuilder = [
  {
    type: "input",
    label: "Menu Title",
    state: "title",
    value: "",
  },
  {
    type: "input",
    multiline: true,
    rows: 3,
    label: "Menu Description",
    state: "description",
    value: "",
  },
];
