import axios from "axios";

let base = "/menuSchedules/";

const getPendingMenuScheduleCategories = async () =>
  (await axios.get(base + "pendingMenuScheduleCategories")).data;

const getPendingMenuScheduleDays = async ({ cuisine, mealType, mealTime }) =>
  (
    await axios.get(base + "pendingMenuScheduleDays", {
      params: { cuisine, mealType, mealTime },
    })
  ).data;

const getMenuScheduleCategories = async () =>
  (await axios.get(base + "menuScheduleCategories")).data;

const getMenuScheduleDays = async ({
  kitchenId,
  cuisine,
  mealType,
  mealTime,
}) =>
  (
    await axios.get(base + "menuScheduleDays", {
      params: {
        kitchenId,
        cuisine,
        mealType,
        mealTime,
      },
    })
  ).data;

const schedule = async ({ menuId, day }) =>
  (await axios.post(base, { menuId, day })).data;

const get = async ({ kitchenId, cuisine, mealType, mealTime, day }) =>
  (
    await axios.get(base + "menuSchedule", {
      params: {
        kitchenId,
        cuisine,
        mealType,
        mealTime,
        day,
      },
    })
  ).data;

const menuSchedules = {
  getPendingMenuScheduleCategories,
  getPendingMenuScheduleDays,
  getMenuScheduleCategories,
  getMenuScheduleDays,
  schedule,
  get,
};

export default menuSchedules;
