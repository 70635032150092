import { useEffect, useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { getCurrentDay12AM } from "../utils/dateHelpers";
import { useLocation, useNavigate } from "react-router-dom";
import isDateValid from "../utils/isValidDate";

const DateBar = ({ onDateChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const queryDate = queryParams.get("date");

  const initialDate = useMemo(() => {
    if (queryDate && isDateValid(queryDate)) {
      return dayjs(queryDate);
    }
    return dayjs(getCurrentDay12AM());
  }, [queryDate]);

  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    const isoDate = date.toISOString();
    if (queryDate !== isoDate) {
      queryParams.set("date", isoDate);
      const newSearch = `?${queryParams.toString()}`;
      navigate({ search: newSearch }, { replace: true });
    }
  }, [date, queryDate, queryParams, navigate]);

  useEffect(() => {
    onDateChange(date.toISOString());
  }, [date, onDateChange]);

  const handleDateSelection = (date) => {
    setDate(date);
  };

  const handlePrevDay = () => {
    setDate((prevValue) => prevValue.subtract(1, "day"));
  };

  const handleNextDay = () => {
    setDate((prevValue) => prevValue.add(1, "day"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <KeyboardArrowLeftIcon
          onClick={handlePrevDay}
          sx={{ cursor: "pointer" }}
        />
        <MobileDatePicker
          slotProps={{
            textField: {
              size: "small",
            },
          }}
          label="Date"
          format="ddd, MMM DD, YYYY"
          value={date}
          onChange={handleDateSelection}
        />
        <KeyboardArrowRightIcon
          onClick={handleNextDay}
          sx={{ cursor: "pointer" }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DateBar;
