import KitchenActivationPage from "./pages/KitchenActivationPage";
import KitchenPage from "./pages/KitchenPage";

const kitchenRoutes = [
  {
    path: "kitchen",
    component: <KitchenPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
  {
    path: "kitchen/activate",
    component: <KitchenActivationPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
];

export default kitchenRoutes;
