import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import DateBar from "../../../components/DateBar";
import { TaskList } from "../components";

import { useKitchen } from "../../../contexts/KitchenContext";
import { useState } from "react";

const DispatchTasksPage = () => {
  const navigate = useNavigate();
  const { kitchen } = useKitchen();
  const [date, setDate] = useState(null);

  if (kitchen.status === "pending") {
    return navigate("/kitchen-activation", { replace: true });
  }

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Dispatch Tasks</PageTitle>
        <DateBar onDateChange={setDate} />
        {date && <TaskList date={date} />}
      </Container>
    </>
  );
};

export default DispatchTasksPage;
