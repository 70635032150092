import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import ColonText from "../../../components/ColonText";
import MenuDisplay from "../../../components/MenuDisplay";

import api from "../../../api";
import { useData } from "../../../contexts/DataContext";
import { useKitchen } from "../../../contexts/KitchenContext";

const MenuScheduleInfo = () => {
  const { kitchen } = useKitchen();
  const { cuisine, mealType, mealTime, day } = useParams();
  const data = useData();
  const [menuSchedule, setMenuSchedule] = useState(null);

  useEffect(() => {
    const fetchMenuSchedule = async () => {
      try {
        const {
          data: { menuSchedule },
        } = await api.menuSchedules.get({
          kitchenId: kitchen._id,
          cuisine,
          mealType,
          mealTime,
          day,
        });

        setMenuSchedule(menuSchedule);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMenuSchedule();
  }, []);

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <ColonText first="Cuisine" second={data.cuisineLabels[cuisine]} />
        <ColonText first="Meal Type" second={data.mealTypeLabels[mealType]} />
        <ColonText first="Meal Time" second={data.mealTimeLabels[mealTime]} />
        <ColonText first="Day" second={data.dayLabels[day]} />
        {menuSchedule && (
          <MenuDisplay
            title={menuSchedule.menu.title}
            description={menuSchedule.menu.description}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default MenuScheduleInfo;
