import React from "react";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";

import ColonText from "../../../components/ColonText";
import formatTimestamp from "../../../utils/formatTimestamp";

const PaymentSummary = ({ payment }) => {
  const { amount, paymentMode, finalStatus, isLatePaymentReceived } = payment;

  return (
    <Card sx={{ margin: "10px 0px" }}>
      <CardContent>
        <ColonText first={"Amount"} second={"₹" + amount} />
        <ColonText first={"Payment Mode"} second={paymentMode} />
        <ColonText first={"Status"} second={finalStatus} />
        {payment.finalStatus === "expired" && (
          <ColonText
            first={"Payment Expired on"}
            second={formatTimestamp(payment.expiry)}
          />
        )}
        {payment.finalStatus === "completed" && (
          <ColonText
            first={"Payment received on"}
            second={formatTimestamp(
              payment.timeline[payment.timeline.length - 1].timestamp
            )}
          />
        )}
        {isLatePaymentReceived && (
          <Typography variant="caption" color={"orange"}>
            Late payment received and check refund details below
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default PaymentSummary;
