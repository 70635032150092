import { useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";

import ColonText from "../../../components/ColonText";
import { useData } from "../../../contexts/DataContext";

const TaskDetails = () => {
  const { date, cuisine, mealType, mealTime } = useParams();
  const { cuisineLabels, mealTypeLabels, mealTimeLabels } = useData();

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <ColonText first="Date" second={new Date(date).toDateString()} />
        <ColonText first="Cuisine" second={cuisineLabels[cuisine]} />
        <ColonText first="Meal Type" second={mealTypeLabels[mealType]} />
        <ColonText first="Meal Time" second={mealTimeLabels[mealTime]} />
      </CardContent>
    </Card>
  );
};

export default TaskDetails;
