import authRoutes from "../../modules/Auth/routes";
import homeRoutes from "../../modules/Home/routes";
import kitchenRoutes from "../../modules/Kitchen/routes";
import kitchenRegistrationRoutes from "../../modules/KitchenRegistration/routes";
import menuScheduleRoutes from "../../modules/MenuSchedules/routes";
import menuRoutes from "../../modules/Menus/routes";
import kitchenUserRoutes from "../../modules/KitchenUsers/routes";
import dispatchTaskRoutes from "../../modules/DispatchTasks/routes";
import deliveryTaskRoutes from "../../modules/DeliveryTasks/routes";
import orderRoutes from "../../modules/Orders/routes";
import settlementRoutes from "../../modules/Settlements/routes";

const routes = [
  ...authRoutes,
  ...homeRoutes,
  ...kitchenRegistrationRoutes,
  ...kitchenRoutes,
  ...menuScheduleRoutes,
  ...menuRoutes,
  ...kitchenUserRoutes,
  ...dispatchTaskRoutes,
  ...deliveryTaskRoutes,
  ...orderRoutes,
  ...settlementRoutes,
];

export default routes;
