import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import Header from "../../../components/Header";
import ExistingRegistration from "../components/ExistingRegistration";
import NewRegistration from "../components/NewRegistration";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const KitchenRegistrationPage = () => {
  const [kitchenRegistration, setKitchenRegistration] = useState({});

  const isNewKitchenRegistration = kitchenRegistration._id ? false : true;

  useEffect(() => {
    const fetchKitchenRegistration = async () => {
      try {
        const res = await api.kitchenRegistrations.getMyKitchenRegistration();
        setKitchenRegistration(res.data.kitchenRegistration);
      } catch (error) {
        handleError(error);
      }
    };
    fetchKitchenRegistration();
  }, []);

  return (
    <>
      <Header />
      <Container>
        {isNewKitchenRegistration ? (
          <NewRegistration />
        ) : (
          <ExistingRegistration kitchenRegistration={kitchenRegistration} />
        )}
      </Container>
    </>
  );
};

export default KitchenRegistrationPage;
