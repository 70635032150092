import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitleWithButton from "../../../components/PageTitleWithButton";
import MenuCard from "../components/MenuCard";

import api from "../../../api";
import NoListFound from "../../../components/NoListFound";

const MenusPage = () => {
  const navigate = useNavigate();
  const [menus, setMenus] = useState([]);

  const fetchMenus = useCallback(async () => {
    const {
      data: { menus },
    } = await api.menus.getAll();
    setMenus(menus);
  }, []);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  return (
    <>
      <Header />
      <Container>
        <PageTitleWithButton
          title={"Menus"}
          button={
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate("/menus/add")}
            >
              Add Menu
            </Button>
          }
        />
        {menus.map((menu) => (
          <MenuCard key={menu._id} menu={menu} fetchMenus={fetchMenus} />
        ))}
        {menus.length === 0 && <NoListFound text={"No menus found"} />}
      </Container>
    </>
  );
};

export default MenusPage;
