import axios from "axios";
import getAll from "../utils/getAll";

let base = "/settlements/";

const getAllSettlements = getAll(base);

const getSettlement = async ({ settlementId }) =>
  (await axios.get(base + settlementId + "?role=kitchen")).data;

const settlements = {
  getAllSettlements,
  getSettlement,
};

export default settlements;
