import SectionTitleWithButton from "../../../components/SectionTitleWithButton";
import AddressSummary from "../../../components/AddressSummary";
import { useOrderPageContext } from "../contexts/OrderPageContext";

const Address = () => {
  const { order } = useOrderPageContext();

  return (
    <>
      <SectionTitleWithButton title="Address" />
      <AddressSummary address={order.address} />
    </>
  );
};

export default Address;
