function railwayTimeToSeconds(timeStr) {
  // Split the input time string into hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Validate input
  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    throw new Error("Invalid time format");
  }

  // Calculate total seconds from midnight
  return hours * 3600 + minutes * 60;
}

export default railwayTimeToSeconds;
