import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import Auth from "../components/Auth";

const AuthPage = () => {
  return (
    <>
      <Header hideBackButton />
      <Container>
        <PageTitle>Kitchen portal</PageTitle>
        <Auth />
      </Container>
    </>
  );
};

export default AuthPage;
