import AuthPage from "./pages/AuthPage";
import LoginWithOtpPage from "./pages/LoginWithOtpPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

const authRoutes = [
  {
    path: "auth",
    component: <AuthPage />,
    isProtected: false,
  },
  {
    path: "auth/login-with-otp",
    component: <LoginWithOtpPage />,
    isProtected: false,
  },
  {
    path: "auth/reset-password",
    component: <ResetPasswordPage />,
    isProtected: false,
  },
];

export default authRoutes;
