import DeliveryTasksPage from "./pages/DeliveryTasksPage";

const deliveryTaskRoutes = [
  {
    path: "delivery-tasks",
    component: <DeliveryTasksPage />,
    allowedKitchenRoles: ["owner", "admin", "rider"],
    isProtected: true,
  },
];

export default deliveryTaskRoutes;
