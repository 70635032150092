import * as Yup from "yup";

export const validationSchema = Yup.object({
  cuisine: Yup.string().required("Cuisine is required"),
  mealType: Yup.string().required("Meal Type is required"),
  mealTime: Yup.string().required("Meal Time is required"),
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
});

export const initialValues = {
  cuisine: "",
  mealType: "",
  mealTime: "",
  title: "",
  description: "",
};
