import FormComponents from "../../../components/FormComponents";

import { useData } from "../../../contexts/DataContext";
import { useKitchen } from "../../../contexts/KitchenContext";

const MenuFormComponents = ({ formik }) => {
  const { kitchen } = useKitchen();
  const data = useData();
  return (
    <FormComponents
      formik={formik}
      data={[
        {
          type: "select",
          label: "Cuisine",
          state: "cuisine",
          options: kitchen.configuration.cuisines.map((cuisine) => {
            return {
              label: data.cuisineLabels[cuisine],
              value: cuisine,
            };
          }),
          value: "",
        },
        {
          type: "select",
          label: "Meal Type",
          state: "mealType",
          options: kitchen.configuration.mealTypes.map((mealType) => {
            return {
              label: data.mealTypeLabels[mealType],
              value: mealType,
            };
          }),
          value: "",
        },
        {
          type: "select",
          label: "Meal Time",
          state: "mealTime",
          options: kitchen.configuration.mealTimes.map((mealTime) => {
            return {
              label: data.mealTimeLabels[mealTime],
              value: mealTime,
            };
          }),
          value: "",
        },
        {
          type: "input",
          label: "Menu Title",
          state: "title",
          value: "",
        },
        {
          type: "input",
          multiline: true,
          rows: 3,
          label: "Menu Description",
          state: "description",
          value: "",
        },
      ]}
    />
  );
};

export default MenuFormComponents;
