import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../api";
import { useParams } from "react-router-dom";
import handleError from "../../../utils/handleError";
import { toast } from "react-toastify";
import FormComponents from "../../../components/FormComponents";

const validationSchema = yup.object({
  reason: yup.string().required("Reason is required"),
});

const MissDeliveryDialog = ({ open, setOpen, orderId, fetchDeliveries }) => {
  const { date } = useParams();

  const closeDialog = () => {
    formik.resetForm();
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.dispatchTasks.missDelivery({
          date,
          orderId,
          reason: values.reason,
        });
        toast.success("Delivery missed successfully");
        await fetchDeliveries();
      } catch (error) {
        handleError(error);
      } finally {
        formik.resetForm();
        closeDialog();
      }
    },
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Are you sure you want to miss this delivery?
          </Typography>
          <FormComponents
            formik={formik}
            data={[
              {
                type: "input",
                label: "Reason",
                state: "reason",
                multiline: true,
                rows: 3,
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" color="success" onClick={closeDialog}>
            Back
          </Button>
          <Button type="submit" color="error">
            Miss
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MissDeliveryDialog;
