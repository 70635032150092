import SettlementsPage from "./pages/SettlementsPage";

const settlementRoutes = [
  {
    path: "settlements",
    component: <SettlementsPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
];

export default settlementRoutes;
