import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const DeliveryPageHeader = () => {
  const { date } = useParams();

  return (
    <div
      style={{
        margin: "20px 0px",
      }}
    >
      <Typography variant="h5">Delivery</Typography>
      <Typography variant="subtitle1">
        {new Date(date).toDateString()}
      </Typography>
    </div>
  );
};

export default DeliveryPageHeader;
