import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import MenuFormComponents from "../components/MenuFormComponents";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import filterObj from "../../../utils/filterObj";
import { initialValues, validationSchema } from "../utils/menuFormData";

const EditMenuPage = () => {
  const navigate = useNavigate();
  const { menuId } = useParams();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.menus.update({
          menuId,
          ...values,
        });
        toast.success("Menu edited successfully");
        navigate(-1);
      } catch (err) {
        handleError(err);
      }
    },
  });

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const {
          data: { menu },
        } = await api.menus.get({ menuId });
        formik.setValues(filterObj(menu, ...Object.keys(formik.values)));
      } catch (err) {
        handleError(err);
      }
    };
    fetchMenu();
  }, [menuId]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Edit Menu</PageTitle>
        <form onSubmit={formik.handleSubmit}>
          <MenuFormComponents formik={formik} />
          <Button type="submit" fullWidth variant="contained">
            Edit Menu
          </Button>
        </form>
      </Container>
    </>
  );
};

export default EditMenuPage;
