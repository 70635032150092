import ProgressBar from "./ProgressBar";

import { deliveryStatusToColorMapping } from "../../../utils/mappings";

const DeliveryProgress = ({ order }) => {
  const {
    completedDates,
    deliverableDates,
    skippedDates,
    skipLimit,
    missedDates,
    missLimit,
  } = order;

  const deliveryProgressList = [
    {
      actual: completedDates.length,
      total: deliverableDates.length,
      text: "Completed",
      color: deliveryStatusToColorMapping.completed,
    },
    {
      actual: skippedDates.length,
      total: skipLimit,
      text: "Skipped by user",
      color: deliveryStatusToColorMapping.skipped,
    },
    {
      actual: missedDates.length,
      total: missLimit,
      text: "Missed by kitchen",
      color: deliveryStatusToColorMapping.missed,
    },
  ];

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "10px",
      }}
    >
      {deliveryProgressList.map((deliveryProgress, index) => (
        <ProgressBar
          key={index}
          actual={deliveryProgress.actual}
          total={deliveryProgress.total}
          text={deliveryProgress.text}
          color={deliveryProgress.color}
        />
      ))}
    </div>
  );
};

export default DeliveryProgress;
