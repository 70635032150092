import { Container } from "@mui/material";

import Header from "../../../components/Header";
import { Orders } from "../components";
import PageTitle from "../../../components/PageTitle";

const OrdersPage = () => {
  return (
    <>
      <Header />
      <Container>
        <PageTitle>Orders</PageTitle>
        <Orders />
      </Container>
    </>
  );
};

export default OrdersPage;
