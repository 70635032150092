import { Box } from "@mui/material";
import MissDeliveryButton from "./MissDeliveryButton";
import CompleteDeliveryButton from "./CompleteDeliveryButton";

const DeliveryActions = ({ deliveryId, fetchNearestDelivery }) => {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "15px",
          my: 2,
        }}
      >
        <CompleteDeliveryButton
          deliveryId={deliveryId}
          fetchNearestDelivery={fetchNearestDelivery}
        />
        <MissDeliveryButton
          deliveryId={deliveryId}
          fetchNearestDelivery={fetchNearestDelivery}
        />
      </Box>
    </>
  );
};

export default DeliveryActions;
