import axios from "axios";

let base = "/kitchenUserInvites/";

const sendInvite = async ({ email, role }) =>
  (await axios.post(base, { email, role })).data;

const getAllSentInvites = async () => (await axios.get(base + "sent")).data;

const cancelInvite = async ({ inviteId }) =>
  (await axios.patch(base + inviteId + "/cancel")).data;

const getAllActiveReceivedInvites = async () =>
  (await axios.get(base + "activeReceived")).data;

const acceptInvite = async ({ inviteId }) =>
  (await axios.patch(base + inviteId + "/accept")).data;

const declineInvite = async ({ inviteId }) =>
  (await axios.patch(base + inviteId + "/decline")).data;

const kitchenUserInvites = {
  sendInvite,
  getAllSentInvites,
  cancelInvite,
  getAllActiveReceivedInvites,
  acceptInvite,
  declineInvite,
};

export default kitchenUserInvites;
