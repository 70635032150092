import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";

import CategoryTitle from "../../../components/CategoryTitle";
import MenuDisplay from "../../../components/MenuDisplay";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const MenuCard = ({ menu, fetchMenus }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleDelete = async () => {
    try {
      await api.menus.delete({ menuId: menu._id });
      toast.success("Menu deleted successfully");
      fetchMenus();
    } catch (err) {
      handleError(err);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Card sx={{ my: 2 }}>
        <CardContent>
          <CategoryTitle
            cuisine={menu.cuisine}
            mealType={menu.mealType}
            mealTime={menu.mealTime}
          />
          <MenuDisplay
            title={menu.title}
            description={menu.description}
            hideMenuLabel
          />
          <Button
            color="warning"
            onClick={() => navigate(`/menus/${menu._id}/edit`)}
          >
            Edit
          </Button>
          <Button color="error" onClick={openDialog}>
            Delete
          </Button>
        </CardContent>
      </Card>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this menu?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuCard;
