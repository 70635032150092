import { Container, Typography } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import {
  TaskDetails,
  MenuSchedule,
  ActionButtons,
  DeliverySummary,
  GoToDeliveryTasksPageButton,
  DeliveryList,
} from "../components";

import useDispatchTaskPage from "../hooks/useDispatchTaskPage";
import DeliveryWindowAlert from "../components/DeliveryWindowAlert";

const DispatchTaskPage = () => {
  const {
    deliveries,
    fetchDeliveries,
    isDeliveryWindowOpen,
    deliveryWindowOpenTime,
    deliveryWindowCloseTime,
  } = useDispatchTaskPage();

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Dispatch Task</PageTitle>
        <TaskDetails />
        <MenuSchedule />
        <Typography variant="h6" sx={{ my: 2 }}>
          Deliveries
        </Typography>
        <DeliveryWindowAlert
          isDispatchwindowOpen={isDeliveryWindowOpen}
          deliveryWindowOpenTime={deliveryWindowOpenTime}
          deliveryWindowCloseTime={deliveryWindowCloseTime}
        />
        {isDeliveryWindowOpen && (
          <ActionButtons fetchDeliveries={fetchDeliveries} />
        )}
        <DeliverySummary deliveries={deliveries} />
        {isDeliveryWindowOpen && <GoToDeliveryTasksPageButton />}
        <DeliveryList
          deliveries={deliveries}
          fetchDeliveries={fetchDeliveries}
          isDeliveryWindowOpen={isDeliveryWindowOpen}
        />
      </Container>
    </>
  );
};

export default DispatchTaskPage;
