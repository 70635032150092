import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import CategoryTitle from "../../../components/CategoryTitle";
import MenuScheduleDayCard from "../components/MenuScheduleDayCard";

import api from "../../../api";
import { useKitchen } from "../../../contexts/KitchenContext";
import handleError from "../../../utils/handleError";

const MenuScheduleDaysPage = () => {
  const { kitchen } = useKitchen();
  const { cuisine, mealType, mealTime } = useParams();
  const [menuScheduleDays, setMenuScheduleDays] = useState([]);

  useEffect(() => {
    const fetchMenuScheduleDays = async () => {
      try {
        const {
          data: { menuScheduleDays },
        } = await api.menuSchedules.getMenuScheduleDays({
          kitchenId: kitchen._id,
          cuisine,
          mealType,
          mealTime,
        });
        setMenuScheduleDays(menuScheduleDays);
      } catch (error) {
        handleError(error);
      }
    };
    fetchMenuScheduleDays();
  }, [cuisine, mealType, mealTime, kitchen._id]);

  return (
    <>
      <Header />
      <Container>
        <PageTitle>Menu Schedules</PageTitle>
        <CategoryTitle
          cuisine={cuisine}
          mealType={mealType}
          mealTime={mealTime}
          textAlign="center"
        />
        {menuScheduleDays.map(({ day, menuSchedule }) => (
          <MenuScheduleDayCard
            key={day}
            day={day}
            menuSchedule={menuSchedule}
          />
        ))}
      </Container>
    </>
  );
};

export default MenuScheduleDaysPage;
