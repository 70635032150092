import { Typography } from "@mui/material";

const ColonText = ({ first, second }) => {
  return (
    <Typography sx={{ my: 1 }}>
      <strong>{first} : </strong> {second}
    </Typography>
  );
};

export default ColonText;
