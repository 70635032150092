function convertToAMPM(time) {
  // First, let's handle the case where the input might use a dot instead of a colon
  time = time.replace(".", ":");

  // Split the time string into hours and minutes
  let [hours, minutes] = time.split(":");

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Determine if it's AM or PM
  let period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Pad single-digit hours with a leading zero
  hours = hours.toString().padStart(2, "0");

  // Ensure minutes are padded with a leading zero if necessary
  minutes = minutes.padStart(2, "0");

  // Return the formatted time string
  return `${hours}:${minutes} ${period}`;
}

export default convertToAMPM;
