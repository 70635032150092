function isTimeInRange(range) {
  // Get current time
  const now = new Date();
  const currentHours = now.getHours();
  const currentMinutes = now.getMinutes();
  const currentTime = currentHours * 60 + currentMinutes;

  // Parse start time
  const [startHours, startMinutes] = range.start.split(":").map(Number);
  const startTime = startHours * 60 + startMinutes;

  // Parse end time
  const [endHours, endMinutes] = range.end.split(":").map(Number);
  const endTime = endHours * 60 + endMinutes;

  // Check if current time is within range
  return currentTime >= startTime && currentTime <= endTime;
}

export default isTimeInRange;
