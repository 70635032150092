import HomePage from "./pages/HomePage";

const homeRoutes = [
  {
    path: "/",
    component: <HomePage />,
    isProtected: true,
  },
];

export default homeRoutes;
