import React, { useCallback, useContext, useEffect, useState } from "react";
import api from "../api";
import { useAuth } from "./AuthContext";
import handleError from "../utils/handleError";

const KitchenContext = React.createContext();

export const useKitchen = () => useContext(KitchenContext);

const KitchenContextProvider = ({ children }) => {
  const authCtx = useAuth();
  const [loading, setLoading] = useState(true);
  const [kitchen, setKitchen] = useState({});
  const [kitchenRole, setKitchenRole] = useState("");

  const fetchMyKitchenDetails = useCallback(async () => {
    try {
      setLoading(true);
      const res = await api.kitchens.getMyKitchenDetails();
      const { kitchen, kitchenUser } = res.data;

      setKitchen(kitchen);
      if (kitchenUser) {
        setKitchenRole(kitchenUser.role);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setKitchen({});
      setKitchenRole("");
      handleError(err);
    }
  }, []);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchMyKitchenDetails();
    } else {
      setKitchen({});
      setKitchenRole("");
    }
  }, [authCtx.isLoggedIn, fetchMyKitchenDetails]);

  const contextValue = {
    kitchen,
    kitchenRole,
    fetchMyKitchenDetails,
    loading,
  };

  return (
    <KitchenContext.Provider value={contextValue}>
      {children}
    </KitchenContext.Provider>
  );
};

export default KitchenContextProvider;
