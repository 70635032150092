import {
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
} from "@mui/material";
import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import { useKitchen } from "../../../contexts/KitchenContext";
import { ArrowForward } from "@mui/icons-material";
import BasicDetailsDisplay from "../../KitchenRegistration/components/BasicDetailsDisplay";
import AddressDisplay from "../../KitchenRegistration/components/AddressDisplay";
import ConfigurationDisplay from "../../KitchenRegistration/components/ConfigurationDisplay";
import PricingDisplay from "../../KitchenRegistration/components/PricingDisplay";
import BankDetailsDisplay from "../../KitchenRegistration/components/BankDetailsDisplay";
import LegalDetailsDisplay from "../../KitchenRegistration/components/LegalDetailsDisplay";

const KitchenPage = () => {
  const { kitchen } = useKitchen();

  return (
    <>
      <Header />
      <Container>
        <PageTitle>{kitchen.name}</PageTitle>
        <BasicDetailsDisplay
          kitchenName={kitchen.name}
          kitchenTagline={kitchen.tagline}
          ownerName={kitchen.ownerDetails.name}
          ownerPhoneNumber={kitchen.ownerDetails.mobile}
        />
        <AddressDisplay address={kitchen.address} />
        <ConfigurationDisplay configuration={kitchen.configuration} />
        <PricingDisplay
          configuration={kitchen.configuration}
          pricing={kitchen.pricing}
        />
        <BankDetailsDisplay bankDetails={kitchen.bankDetails} />
        <LegalDetailsDisplay legalDetails={kitchen.legalDetails} />
      </Container>
    </>
  );
};

export default KitchenPage;
