import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormComponents from "../../../components/FormComponents";
import OtpVerificationDrawer from "../../../components/OtpVerificationDrawer";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password should be minimum 8 characters")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please Re-enter the password"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const triggerOtp = async (values) => {
    try {
      await api.auth.triggerResetPasswordOtp({
        email: values.email,
        password: values.password,
        passwordConfirm: values.passwordConfirm,
      });
      setIsDrawerOpen(true);
    } catch (error) {
      handleError(error);
    }
  };

  const verifyOtp = async (otp) => {
    try {
      await api.auth.verifyResetPasswordOtp({
        email: formik.values.email,
        otp,
      });
      toast.success("Password reset successful, please login again.");
      navigate("/auth?mode=login", { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: triggerOtp,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormComponents
          formik={formik}
          data={[
            {
              type: "input",
              label: "Email",
              state: "email",
              value: "",
              inputType: "email",
            },
            {
              type: "input",
              label: "Password",
              state: "password",
              value: "",
              inputType: "password",
            },
            {
              type: "input",
              label: "Re-enter Password",
              state: "passwordConfirm",
              value: "",
              inputType: "password",
            },
          ]}
        />
        <Button fullWidth variant="contained" type="submit">
          Verify Email via OTP
        </Button>
      </form>
      <OtpVerificationDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        submitHandler={verifyOtp}
        description="Please enter the 6 digit code sent to your email address to reset your password. valid for 10 minutes."
      />
    </>
  );
};

export default ResetPassword;
