import { Box } from "@mui/material";

const GreyCard = ({ children }) => {
  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "10px",
        backgroundColor: "#ddd",
        margin: "10px 0px",
      }}
    >
      {children}
    </Box>
  );
};

export default GreyCard;
