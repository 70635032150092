import axios from "axios";

let base = "/kitchenRegistrations/";

const initiateKitchenRegistration = async (formData) =>
  (await axios.post(base, formData)).data;

const getMyKitchenRegistration = async () =>
  (await axios.get(base + "myKitchenRegistration")).data;

const verifyMobileOtp = async ({ otp }) =>
  (await axios.patch(base + "verifyMobileOtp", { otp })).data;

const updateBasicDetails = async (formData) =>
  (await axios.patch(base + "basicDetails", formData)).data;

const updateAddress = async ({
  building,
  area,
  landmark,
  district,
  state,
  country,
  pincode,
  latitude,
  longitude,
}) =>
  (
    await axios.patch(base + "address", {
      building,
      area,
      landmark,
      district,
      state,
      country,
      pincode,
      latitude,
      longitude,
    })
  ).data;

const updateConfiguration = async ({
  cuisines,
  mealTypes,
  mealTimes,
  periods,
  servingCapacityPerMealTime,
  deliveryDays,
  deliveryPincodes,
}) =>
  (
    await axios.patch(base + "configuration", {
      cuisines,
      mealTypes,
      mealTimes,
      periods,
      servingCapacityPerMealTime,
      deliveryDays,
      deliveryPincodes,
    })
  ).data;

const updatePricing = async ({
  basePrice,
  cuisineMultipliers,
  mealTypeMultipliers,
  mealTimeMultipliers,
  periodMultipliers,
}) =>
  (
    await axios.patch(base + "pricing", {
      basePrice,
      cuisineMultipliers,
      mealTypeMultipliers,
      mealTimeMultipliers,
      periodMultipliers,
    })
  ).data;

const updateBankDetails = async ({ accountNumber, ifscCode }) =>
  (
    await axios.patch(base + "bankDetails", {
      accountNumber,
      ifscCode,
    })
  ).data;

const updateLegalDetails = async ({ pan, gstin, fssai }) =>
  (
    await axios.patch(base + "legalDetails", {
      pan,
      gstin,
      fssai,
    })
  ).data;

const submit = async ({ notes }) =>
  (
    await axios.patch(base + "submit", {
      notes,
    })
  ).data;

const kitchenRegistrations = {
  initiateKitchenRegistration,
  getMyKitchenRegistration,
  verifyMobileOtp,
  updateBasicDetails,
  updateAddress,
  updateConfiguration,
  updatePricing,
  updateBankDetails,
  updateLegalDetails,
  submit,
};

export default kitchenRegistrations;
