import { useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import CategoryTitle from "../../../components/CategoryTitle";
import MenuDisplay from "../../../components/MenuDisplay";

const TaskCard = ({
  date,
  cuisine,
  mealType,
  mealTime,
  numberOfOrders,
  numberOfMeals,
  menuSchedule,
}) => {
  const navigate = useNavigate();

  const clickHandler = async () => {
    navigate(`/dispatch-tasks/${date}/${cuisine}/${mealType}/${mealTime}`);
  };

  return (
    <Card
      sx={{
        margin: "15px 0px",
      }}
    >
      <CardContent>
        <CategoryTitle
          cuisine={cuisine}
          mealType={mealType}
          mealTime={mealTime}
        />
        <MenuDisplay
          title={menuSchedule?.menu.title}
          description={menuSchedule?.menu.description}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "15px 0px",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Meals : {numberOfMeals}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Orders : {numberOfOrders}
          </Typography>
        </Box>
        <Button
          size="small"
          fullWidth
          variant="contained"
          onClick={clickHandler}
          endIcon={<ArrowForward />}
        >
          View details
        </Button>
      </CardContent>
    </Card>
  );
};

export default TaskCard;
