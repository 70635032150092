import React, { useEffect, useState } from "react";
import { FormHelperText, Typography, Box, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const MAX_IMAGE_SIZE_MB = 2; // Set your maximum image size in megabytes

const ImageUpload = ({ label, onImageUpload, imageSrc }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];

    // Check if the file size exceeds the maximum size
    if (file && file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      setError(`Image size should be less than ${MAX_IMAGE_SIZE_MB} MB.`);
      return;
    }

    setSelectedFile(file);
    setIsImageUploaded(true);
    setError("");
  };

  const handleRemove = () => {
    setSelectedFile(null);
    setIsImageUploaded(false);
    setError("");
  };

  useEffect(() => {
    if (selectedFile) {
      onImageUpload(selectedFile);
    }
  }, [selectedFile, onImageUpload]);

  useEffect(() => {
    // Clean up the object URL
    return () => {
      if (selectedFile) {
        URL.revokeObjectURL(selectedFile.preview);
      }
    };
  }, [selectedFile]);

  return (
    <div>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: "5px" }}
        color="textSecondary"
      >
        {label}
      </Typography>
      {isImageUploaded ? (
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={handleRemove}
          aria-label="remove image"
        >
          Remove Image <DeleteIcon sx={{ marginLeft: "5px" }} />
        </Button>
      ) : (
        <>
          <input
            type="file"
            accept="image/*"
            id="image-upload-input"
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <label htmlFor="image-upload-input">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              component="span"
              aria-label="upload image"
            >
              {imageSrc ? "Change Image" : "Upload Image"}
              <CloudUploadIcon sx={{ marginLeft: "5px" }} />
            </Button>
          </label>
          {error && (
            <FormHelperText style={{ color: "red", marginTop: "5px" }}>
              {error}
            </FormHelperText>
          )}
        </>
      )}
      {selectedFile && (
        <>
          <Box
            sx={{
              marginTop: "10px",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={URL.createObjectURL(selectedFile)}
              alt={`${label} Preview`}
              style={{ width: "100%", height: "auto" }}
              aria-describedby="selected-image-helper-text"
            />
          </Box>
          <FormHelperText
            style={{ color: "green" }}
            id="selected-image-helper-text"
          >
            Image selected: {selectedFile.name}
          </FormHelperText>
        </>
      )}

      {!selectedFile && imageSrc && (
        <Box
          sx={{
            marginTop: "10px",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <img
            src={imageSrc}
            alt={`${label} Preview`}
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      )}
    </div>
  );
};

export default ImageUpload;
