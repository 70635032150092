import { IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";

const DialerIcon = ({ mobile }) => {
  const openDialer = () => {
    const dialerUrl = `tel:${mobile}`;
    window.open(dialerUrl);
  };

  return (
    <IconButton
      size="small"
      onClick={openDialer}
      sx={{
        backgroundColor: "#4caf50",
        "&:hover, &:focus": {
          backgroundColor: "#4caf50",
        },
      }}
    >
      <PhoneIcon sx={{ color: "#fff" }} fontSize="10" />
    </IconButton>
  );
};

export default DialerIcon;
