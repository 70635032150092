import React from "react";
import { Card, CardContent, Button, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";

import NoListFound from "../../../components/NoListFound";
import formatTimestamp from "../../../utils/formatTimestamp";
import ColonText from "../../../components/ColonText";

const Settlements = ({
  settlements,
  page,
  rowsPerPage,
  totalCount,
  onPageChange,
}) => {
  const pageCount = Math.ceil(totalCount / rowsPerPage);

  const handlePageChange = (event, newPage) => {
    onPageChange(event, newPage - 1);
  };

  return (
    <>
      {settlements.map((settlement) => (
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <ColonText
              first={"Order ID"}
              second={
                <Link to={"/orders/" + settlement.order}>
                  {settlement.order}
                </Link>
              }
            />
            <ColonText
              first={"Amount"}
              second={"₹ " + settlement.settlementAmount}
            />
            <ColonText first={"Status"} second={settlement.status} />
            <ColonText
              first={"Created at"}
              second={formatTimestamp(settlement.createdAt)}
            />
          </CardContent>
        </Card>
      ))}
      {settlements.length === 0 && (
        <NoListFound text={"No Settlements Found"} />
      )}
      {settlements.length > 0 && (
        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <Pagination
            count={pageCount}
            page={page + 1}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </>
  );
};

export default Settlements;
