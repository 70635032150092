export const getCurrentDay12AM = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.toISOString();
};

const getNextDay12AM = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.toISOString();
};

const getPreviousDay12AM = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.toISOString();
};

const isCurrentDate = (date) =>
  new Date(date).getTime() === new Date(getCurrentDay12AM()).getTime();

const isPastDate = (date) =>
  new Date(date).getTime() < new Date(getCurrentDay12AM()).getTime();

const isFutureDate = (date) =>
  new Date(date).getTime() > new Date(getCurrentDay12AM()).getTime();

const dateHelpers = {
  getCurrentDay12AM,
  getNextDay12AM,
  getPreviousDay12AM,
  isCurrentDate,
  isPastDate,
  isFutureDate,
};

export default dateHelpers;
