import { useState } from "react";

import DeliveryCard from "./DeliveryCard";
import MissDeliveryDialog from "./MissDeliveryDialog";
import DispatchDeliveryDialog from "./DispatchDeliveryDialog";
import NoListFound from "../../../components/NoListFound";

const DeliveryList = ({
  deliveries,
  fetchDeliveries,
  isDeliveryWindowOpen,
}) => {
  const [dispatchDialogOpen, setDispatchDialogOpen] = useState(false);
  const [missDialogOpen, setMissDialogOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");

  const dispatchHandler = (orderId) => {
    setSelectedOrderId(orderId);
    setDispatchDialogOpen(true);
  };

  const missHandler = (orderId) => {
    setSelectedOrderId(orderId);
    setMissDialogOpen(true);
  };

  return (
    <>
      {deliveries.map((delivery) => (
        <DeliveryCard
          delivery={delivery}
          dispatchHandler={dispatchHandler}
          missHandler={missHandler}
          isDeliveryWindowOpen={isDeliveryWindowOpen}
        />
      ))}
      {deliveries.length === 0 && <NoListFound text={"No deliveries found"} />}
      <DispatchDeliveryDialog
        open={dispatchDialogOpen}
        setOpen={setDispatchDialogOpen}
        orderId={selectedOrderId}
        fetchDeliveries={fetchDeliveries}
      />
      <MissDeliveryDialog
        open={missDialogOpen}
        setOpen={setMissDialogOpen}
        orderId={selectedOrderId}
        fetchDeliveries={fetchDeliveries}
      />
    </>
  );
};

export default DeliveryList;
