import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { toast } from "react-toastify";

const LocationAccess = ({ onCurrentLocationChange }) => {
  const [currentLocation, setCurrentLocation] = useState({});

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
          toast.error("Error getting location. Please enable location access.");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    onCurrentLocationChange(currentLocation);
  }, [currentLocation, onCurrentLocationChange]);

  return (
    <Box>
      <Typography
        color="textSecondary"
        textAlign={"center"}
        sx={{ margin: "20px 0px" }}
      >
        Please enable location to fetch delivery
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<MyLocationIcon />}
        onClick={getCurrentLocation}
      >
        Allow Location Access
      </Button>
    </Box>
  );
};

export default LocationAccess;
