import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormComponents from "../../../components/FormComponents";
import ImageUpload from "../components/ImageUpload";
import OtpVerificationDrawer from "../../../components/OtpVerificationDrawer";

import api from "../../../api";
import objectToFormData from "../../../utils/objectToFormData";
import Header from "../../../components/Header";
import PageTitle from "../../../components/PageTitle";
import { Container } from "@mui/material";
import BottomButton from "../../../components/BottomButton";
import handleError from "../../../utils/handleError";

const BasicDetailsPage = () => {
  const navigate = useNavigate();
  const [kitchenRegistration, setKitchenRegistration] = useState({});
  const [kitchenCoverImageFile, setKitchenCoverImageFile] = useState(null);
  const [kitchenCoverImageSrc, setKitchenCoverImageSrc] = useState(null);
  const [isOtpVerificationOpen, setIsOtpVerificationOpen] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const isNewKitchenRegistration = kitchenRegistration._id ? false : true;

  const submitHandler = async (values) => {
    try {
      if (isNewKitchenRegistration) {
        if (!kitchenCoverImageFile) {
          return toast.error("Kitchen cover image is required");
        }
        const formData = objectToFormData({
          ...values,
          kitchenCoverImage: kitchenCoverImageFile,
        });
        await api.kitchenRegistrations.initiateKitchenRegistration(formData);
        setIsOtpVerificationOpen(true);
      } else {
        delete values.ownerMobile;
        let formData;
        if (kitchenCoverImageFile) {
          formData = objectToFormData({
            ...values,
            kitchenCoverImage: kitchenCoverImageFile,
          });
        } else {
          formData = objectToFormData(values);
        }
        await api.kitchenRegistrations.updateBasicDetails(formData);
        if (isMobileVerified) {
          navigate(`/kitchen-registration/address`);
        } else {
          setIsOtpVerificationOpen(true);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      kitchenName: "",
      kitchenTagline: "",
      ownerName: "",
      ownerMobile: "",
    },
    validationSchema: Yup.object({
      kitchenName: Yup.string().required("Kitchen name is required"),
      kitchenTagline: Yup.string(),
      ownerName: Yup.string().required("Owner name is required"),
      ownerMobile: Yup.string().required("Owner mobile is required"),
    }),
    onSubmit: submitHandler,
  });

  const otpSubmitHandler = async (otp) => {
    try {
      await api.kitchenRegistrations.verifyMobileOtp({ otp });
      setIsOtpVerificationOpen(false);
      navigate(`/kitchen-registration/address`);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const fetchBasicDetails = async () => {
      try {
        const res = await api.kitchenRegistrations.getMyKitchenRegistration();
        const { kitchenRegistration } = res.data;
        setKitchenRegistration(kitchenRegistration);
        if (kitchenRegistration._id) {
          formik.setValues({
            ownerName: kitchenRegistration.ownerDetails.name,
            ownerMobile: kitchenRegistration.ownerDetails.mobile,
            kitchenName: kitchenRegistration.name,
            kitchenTagline: kitchenRegistration.tagline,
          });
          setKitchenCoverImageSrc(kitchenRegistration.coverImage.s3Location);
          setIsMobileVerified(
            kitchenRegistration.ownerDetails.isMobileVerified
          );
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchBasicDetails();
  }, []);

  return (
    <>
      <Header />
      <PageTitle>Basic details</PageTitle>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents
            data={[
              {
                type: "input",
                label: "Kitchen name",
                state: "kitchenName",
                inputType: "text",
              },
              {
                type: "input",
                label: "Kitchen tagline (optional)",
                state: "kitchenTagline",
                multiline: true,
                rows: 3,
              },
            ]}
            formik={formik}
          />
          <ImageUpload
            label="Kitchen Cover Image"
            onImageUpload={(image) => setKitchenCoverImageFile(image)}
            imageSrc={kitchenCoverImageSrc}
          />
          <div style={{ height: "25px" }}></div>
          <FormComponents
            data={[
              {
                type: "input",
                label: "Owner name",
                state: "ownerName",
                inputType: "text",
              },
              {
                type: "mobile",
                label: isMobileVerified
                  ? "Owner mobile (verified - can't be changed)"
                  : "Owner mobile",
                state: "ownerMobile",
                inputType: "text",
                disabled: isMobileVerified,
              },
            ]}
            formik={formik}
          />
          <BottomButton
            arrowDirection={isMobileVerified ? "forward" : "upward"}
          >
            {isMobileVerified ? "Continue" : "Verify mobile to proceed"}
          </BottomButton>
        </form>
      </Container>
      <OtpVerificationDrawer
        isOpen={isOtpVerificationOpen}
        setIsOpen={setIsOtpVerificationOpen}
        submitHandler={otpSubmitHandler}
        description="Please enter the 6 digit code sent to your mobile. Valid for 10 minutes."
      />
    </>
  );
};

export default BasicDetailsPage;
