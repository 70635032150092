import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import api from "../../../api";
import handleError from "../../../utils/handleError";
import { useParams } from "react-router-dom";

const DispatchDeliveryDialog = ({
  open,
  setOpen,
  orderId,
  fetchDeliveries,
}) => {
  const { date } = useParams();

  const closeDialog = () => setOpen(false);

  const dispatchDelivery = async () => {
    try {
      await api.dispatchTasks.dispatchDelivery({
        orderId,
        date,
      });
      await fetchDeliveries();
      toast.success("Delivery dispatched successfully");
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogContent>
        <Typography>
          Are you sure you want to dispatch this delivery?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="warning" onClick={closeDialog}>
          Back
        </Button>
        <Button color="success" onClick={dispatchDelivery}>
          Dispatch
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DispatchDeliveryDialog;
