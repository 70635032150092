import { useEffect, useState } from "react";

import OrderCard from "./OrderCard";
import NoListFound from "../../../components/NoListFound";

import api from "../../../api";
import handleError from "../../../utils/handleError";

const Orders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const {
          data: { orders },
        } = await api.orders.getAll();
        setOrders(orders);
      } catch (error) {
        handleError(error);
      }
    };
    fetchOrders();
  }, []);

  return (
    <>
      {orders.length === 0 ? (
        <NoListFound text={"No orders found"} />
      ) : (
        orders.map((order) => {
          return <OrderCard key={order._id} order={order} />;
        })
      )}
    </>
  );
};

export default Orders;
