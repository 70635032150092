import { Alert, Box, Chip, Card, CardContent, Button } from "@mui/material";

import ColonText from "../../../components/ColonText";
import formatTimestamp from "../../../utils/formatTimestamp";
import { deliverystatusToMuiColor } from "../../../utils/mappings";
import dateHelpers from "../../../utils/dateHelpers";
import { useParams } from "react-router-dom";

const DeliveryCard = ({
  delivery,
  dispatchHandler,
  missHandler,
  isDeliveryWindowOpen,
}) => {
  const { date } = useParams();
  const { orderId, address, quantity, status, timeline } = delivery;
  const notes = timeline?.[timeline.length - 1]?.notes;
  const isCurrentDate = dateHelpers.isCurrentDate(date);

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Chip
          size="small"
          label={status}
          color={deliverystatusToMuiColor[status]}
          sx={{ mb: 1 }}
        />
        <ColonText first="Order Id" second={orderId} />
        <ColonText
          first={"Customer Details"}
          second={`${address.fullName}, ${address.mobile}`}
        />
        <ColonText
          first={"Address"}
          second={`${address.building}, ${address.area}, ${address.district}, ${address.state}, ${address.country}, ${address.pincode}`}
        />
        <ColonText first={"Quantity"} second={quantity} />

        {status === "pending" && isCurrentDate && isDeliveryWindowOpen && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => dispatchHandler(orderId)}
            >
              Dispatch
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => missHandler(orderId)}
            >
              Miss
            </Button>
          </Box>
        )}

        {status === "completed" && (
          <Alert severity="success">
            Delivery completed on{" "}
            {formatTimestamp(timeline[timeline.length - 1].timestamp)}
          </Alert>
        )}

        {status === "missed" && notes && (
          <Alert severity="error">Reason : {notes}</Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default DeliveryCard;
