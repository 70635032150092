import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "../../../api";
import isTimeInRange from "../../../utils/isTimeInRange";
import { useData } from "../../../contexts/DataContext";
import convertToAMPM from "../../../utils/convertToAMPM";

const useDispatchTaskPage = () => {
  const { mealTimeDeliveryWindow } = useData();
  const { date, cuisine, mealType, mealTime } = useParams();

  const [deliveries, setDeliveries] = useState([]);
  const deliveryWindowOpenTime = convertToAMPM(
    mealTimeDeliveryWindow[mealTime].start
  );
  const deliveryWindowCloseTime = convertToAMPM(
    mealTimeDeliveryWindow[mealTime].end
  );
  const isDeliveryWindowOpen = isTimeInRange(mealTimeDeliveryWindow[mealTime]);

  const fetchDeliveries = useCallback(async () => {
    const {
      data: { deliveries },
    } = await api.dispatchTasks.getAllDeliveries({
      date,
      cuisine,
      mealType,
      mealTime,
    });
    setDeliveries(deliveries);
  }, [date, cuisine, mealType, mealTime]);

  useEffect(() => {
    fetchDeliveries();
  }, [fetchDeliveries]);

  return {
    deliveries,
    fetchDeliveries,
    isDeliveryWindowOpen,
    deliveryWindowOpenTime,
    deliveryWindowCloseTime,
  };
};

export default useDispatchTaskPage;
